import axios from 'axios/index';
import { UPLOAD_AGENT_CERTIFICATE } from '../../utils/constants';

const uploadCertificate = async (agentId, hash, form) => {

    return await axios.post(UPLOAD_AGENT_CERTIFICATE(agentId),
        form, {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        }
    }).then(resp => {
        return resp;
    }).catch(err => {
        console.error(err)
    })
}

export default uploadCertificate;