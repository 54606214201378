import axios from 'axios/index';
import { UPDATE_AGENT } from '../../utils/constants';

const updateAgent = async (data, hash) => {

    return await axios.put(UPDATE_AGENT,
        data, {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        },

    }).then(resp => {
        return resp;
    }).catch(err => {
        console.error(err)
    })

}

export default updateAgent;