import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import getComments from "../../services/GET/getComments";
import { useEffect } from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import addComment from "../../services/POST/addComment";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { isOpenComments, setOpenComments } from "./userDetailsSlice";

// add post comments after
const useStyles = makeStyles({
  root: {
    margin: "auto",
    width: "fit-content",
    textAlign: "center",
    marginTop: 30,
    maxHeight: 500,
    overflowY: "auto",
  },
  table: {
    maxHeight: 500,
  },
  commentBox: {
    resize: "none",
    width: "100%",
    padding: 7,
  },
  commentsCell: {
    width: 900,
  },
  dateCell: {
    width: 100,
  },
});

const CommentsTable = ({ loadComments, comments, hash, token }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (text.length >= 5) setError(null);
  }, [text]);

  const handleText = () => (event) => {
    setText(event.target.value);
  };

  const handleComment = () => async () => {
    if (text.length >= 5) {
      await addComment(text, hash, token);
      setText("");
      loadComments(text + "update"); // force update on comment add
    } else {
      setError("Моля въведете поне 5 символа");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} padding="dense">
        <TableHead>
          <TableRow>
            <TableCell>Дата</TableCell>
            <TableCell>Коментар</TableCell>
            <TableCell>Модератор</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comments &&
            comments.map((com, i) => {
              return (
                <TableRow key={`${com}--${i}`}>
                  <TableCell className={classes.dateCell} align="left">
                    <Typography>
                      {format(new Date(com.dateCreated), DATE_FORMAT)}
                    </Typography>
                  </TableCell>
                  <TableCell>{com.comment}</TableCell>
                  <TableCell>{com.moderatorName}</TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell
              colSpan={2}
              className={classes.commentsCell}
              align="center"
            >
              <Typography variant="error" color="error">
                {error}
              </Typography>
              <TextareaAutosize
                onChange={handleText()}
                className={classes.commentBox}
                minRows={4}
                placeholder="Коментар..."
                variant="outlined"
                value={text}
              ></TextareaAutosize>
            </TableCell>
            <TableCell className={classes.buttonCell} align="center">
              <Button
                onClick={handleComment()}
                variant="contained"
                color="primary"
              >
                Добави
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CommentsBox = ({ token, hash }) => {
  const classes = useStyles();
  const [comments, setComments] = useState(null);
  const [load, setLoad] = useState(0);
  const dispatch = useDispatch();
  const isOpen = useSelector(isOpenComments);

  useEffect(() => {
    setTimeout(() => {
      loadComments();
    }, 1200);
    // eslint-disable-next-line
  }, [load]);

  const loadComments = () => {
    getComments(token)
      .then((resp) => {
        setComments(resp);
      })
      .catch((e) => console.log(e));
  };

  const handleClose = () => {
    dispatch(setOpenComments(false));
  };

  return (
    <Dialog maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle>Коментари</DialogTitle>
      <DialogContent>
        <Box className={classes.root}>
          {/* <Typography>Коментари</Typography> */}
          {comments !== null ? (
            <CommentsTable
              loadComments={setLoad}
              comments={comments}
              hash={hash}
              token={token}
            />
          ) : (
            <h3>Все още няма Коментари</h3>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentsBox;
