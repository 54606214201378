import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import MainLayout from "./components/layouts/MainLayout";
import UserDetails from "./pages/UserDetails";
import Login from "./pages/Login";
import ContextProvider from "./context/ContextProvider";
import Settings from "./pages/Settings";
import ProtectedRoute from "./ProtectedRoutes/ProtectedRoute";
import PreviewPDF from "./pages/PreviewPDF";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { red, yellow, green } from "@material-ui/core/colors";
import AgentsListing from "./pages/AgentsListing";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import UserService from "./SecuredRoutes/UserService";
import Regix from "./pages/Regix";

const theme = createTheme({
  overrides: {
    MuiTablePagination: {
      caption: "s",
      // labelRowsPerPage: '34'
    },
    MuiSelect: {
      root: {
        padding: 12,
      },
    },
    MuiDataGrid: {
      root: {
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
      },
    },
  },
  status: {
    danger: red[600],
    hoverDanger: red[400],
    warning: yellow[600],
    hoverWarning: yellow[400],
    success: green[600],
    hoverSuccess: green[400],
  },
});

const PageRouter = () => {
  let token = UserService.getToken();

  if (token) {
    localStorage.setItem("hash", "Bearer " + token);
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <Switch>
                <ContextProvider>
                  <MainLayout>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/home" component={MainPage} />
                    <Route exact path="/details/:id" component={UserDetails} />
                    <Route exact path="/regix" component={Regix} />
                    <ProtectedRoute
                      exact
                      path="/settings"
                      component={Settings}
                    />
                    <ProtectedRoute
                      exact
                      path="/preview-pdf"
                      component={PreviewPDF}
                    />
                    <ProtectedRoute
                      exact
                      path="/agents-listing"
                      component={AgentsListing}
                    />
                  </MainLayout>
                </ContextProvider>
              </Switch>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </HashRouter>
      </PersistGate>
    </Provider>
  );
};

export default PageRouter;
