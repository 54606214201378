import React, { useContext, useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import MainContext from "../../context/MainContext";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  msg: {
    width: "675px",
  },
}));

const ScreenMessage = ({ screenKey, screenId }) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const agentUi = context.agentUi;
  const [currentMessage, setCurrentMessage] = useState("");
  const updateMessage = context.updateAgentUi;

  useEffect(() => {
    setCurrentMessage(agentUi[screenKey]);

    // eslint-disable-next-line
  }, [agentUi[screenKey]]);

  const onChange =
    () =>
    ({ target }) => {
      const temp = Object.assign({}, agentUi);
      console.log(temp[screenKey]);
      temp[screenKey] = target.value;
      updateMessage(temp);
    };

  return (
    <TextField
      key={currentMessage}
      onChange={onChange()}
      className={classes.msg}
      multiline
      fullWidth
      variant="outlined"
      defaultValue={currentMessage}
    />
  );
};

export default ScreenMessage;
