import React, { useEffect } from "react";
import {
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  Paper,
  Box,
  TableBody,
  Table,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  isOpenHistory,
  selectCurrentUser,
  setCurrentUser,
  setOpenHistory,
} from "./userDetailsSlice";
import getUserByToken from "../../services/GET/getUserByToken";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    textAlign: "center",
    marginTop: 30,
    maxHeight: 500,
    overflowY: "auto",
    width: 500,
  },
}));

const UserHistory = ({ userId, hash }) => {
  const isOpen = useSelector(isOpenHistory);
  const { history } = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen === true) {
      getUser();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  async function getUser() {
    const user = await getUserByToken(userId, hash);
    dispatch(setCurrentUser(user));
  }

  const handleClose = () => {
    dispatch(setOpenHistory(false));
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle style={{ width: 550 }}>История</DialogTitle>{" "}
      <DialogContent>
        {history.length === 0 ? (
          <Typography align="center">No history yet</Typography>
        ) : (
          <ListHistory history={history} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ListHistory = ({ history }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <TableContainer component={Paper}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Модератор</TableCell>
              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(({ id, dateCreated, moderator, status }) => {
              return (
                <TableRow key={id}>
                  <TableCell>
                    {format(new Date(dateCreated), DATE_FORMAT)}
                  </TableCell>
                  <TableCell>{moderator && moderator.name}</TableCell>
                  <TableCell>{status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserHistory;
