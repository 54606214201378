import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  CardContent,
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  Switch,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/constants";
import { makeStyles } from "@material-ui/styles";
import deleteAgent from "../../services/DELETE/deleteAgent";
import updateAgent from "../../services/PUT/updateAgent";
import getModeratorsNotAttached from "../../services/GET/getModeratorsNotAttached";
import MainContext from "../../context/MainContext";
import { useHistory } from "react-router";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  select: {
    padding: 0,
    position: "relative",
    minWidth: 150,
  },
  agentHash: {
    display: "flex",
    marginLeft: "auto",
    marginTop: 15,
    width: 450,
    cursor: "pointer",
  },
  copyIcon: {
    marginRight: -15,
  },
}));

const Agent = ({ agent, hash, updateAgents }) => {
  const [canUpdate, setCanUpdate] = useState(true);
  const classes = useStyles();
  const context = useContext(MainContext);
  const history = useHistory();
  const { agentHash } = agent;
  const [localName, setLocalName] = useState("");
  const [localEmail, setLocalEmail] = useState(false);
  const [selectVal, setSelectVal] = useState("");
  const [moderators, setModerators] = useState([]);
  const [toolTipText, setToolTipText] = useState("Click to copy");

  const handleSelect = () => (e) => {
    setSelectVal(e.target.value);
    console.log(e.target.value ?? "");
  };

  const getModerators = async () => {
    const data = await getModeratorsNotAttached(hash, agent.id);
    setModerators(data);
    // setModerators([1,2,3,4,5,6]); // test
  };

  useEffect(() => {
    if (agent) {
      setLocalEmail(agent.sendEmails);
      setLocalName(agent.name);
    }
    getModerators();
    // eslint-disable-next-line
  }, [agent]);

  useEffect(() => {
    if (localEmail !== agent.sendEmails || localName !== agent.name) {
      setCanUpdate(false);
    } else {
      setCanUpdate(true);
    }
    // eslint-disable-next-line
  }, [localEmail, localName]);

  const handleName =
    () =>
    ({ target }) => {
      setLocalName(target.value);
    };

  const handleUpdate = async () => {
    await updateAgent(
      {
        id: agent.id,
        name: localName,
        sendEmails: localEmail,
      },
      hash
    );
    updateAgents();
    setCanUpdate(true);
  };

  const handleDelete = async () => {
    await deleteAgent(hash, agent.id);
    updateAgents();
  };

  const handleAgentSettings = () => {
    context.selectAgentID(agent.id);
    history.push("/settings");
  };

  const handleCopyHash = () => {
    navigator.clipboard.writeText(agentHash);
    setToolTipText("Copied to Clipboard !");
    setTimeout(() => {
      setToolTipText("Click to Copy");
    }, 2000);
  };

  return (
    <Box style={{ marginBottom: 20 }} boxShadow={2}>
      <Card>
        <CardContent>
          <Typography>
            {`създаден на ${format(new Date(agent.dateCreated), DATE_FORMAT)}`}{" "}
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    key={localEmail}
                    defaultChecked={localEmail}
                    onChange={({ target }) => setLocalEmail(target.checked)}
                  />
                }
                label="Получаване на Email"
              />
            </Grid>
            <Grid item>
              <TextField
                key={agent.name}
                size="small"
                variant="outlined"
                label="Име"
                value={localName}
                onChange={handleName()}
              />
            </Grid>
            <Grid item lg={3}>
              <FormControl fullWidth>
                <InputLabel
                  disableAnimation={true}
                  focused={true}
                  shrink={true}
                  style={{ top: -10 }}
                  variant="outlined"
                  margin="dense"
                >
                  Модератор
                </InputLabel>
                <Select
                  key={agent.name}
                  value={selectVal}
                  onChange={handleSelect()}
                  className={classes.select}
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {moderators.map((mod, i) => {
                    return (
                      <MenuItem
                        key={`${i}--`}
                        value={`test${i}`}
                      >{`test${i}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                style={{ marginLeft: 5 }}
                onClick={handleUpdate}
                disabled={canUpdate}
                variant="contained"
                color="primary"
              >
                Обнови
              </Button>
              <Button
                style={{ marginLeft: 5 }}
                onClick={handleAgentSettings}
                variant="contained"
                color="primary"
              >
                Настройки
              </Button>
              <Button
                style={{ marginLeft: 5 }}
                onClick={handleDelete}
                variant="contained"
                color="secondary"
              >
                Изтрий
              </Button>
            </Grid>
          </Grid>
          <Tooltip title={toolTipText} placement="right">
            <OutlinedInput
              // onBlur={() => setToolTipText("Click to Copy")}
              onClick={handleCopyHash}
              value={agentHash}
              className={classes.agentHash}
              readOnly
              margin="dense"
              multiline={false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton className={classes.copyIcon}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Agent;
