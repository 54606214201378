import axios from 'axios/index';
import { SET_USER_STATUS } from '../../utils/constants';

const setUserStatus = async (status, hash, token) => {

    return await axios.put(SET_USER_STATUS(token, status),
        {}, {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        }
    }).then(resp => {
        // console.log(resp)
        // return resp.data;
    }).catch(err => {
        console.error(err)
    })
}

export default setUserStatus;