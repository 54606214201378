import React from "react";
import Dialog from "@material-ui/core/Dialog";
import ReactPlayer from "react-player/lazy";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Cancel from "@material-ui/icons/CancelOutlined";
import DialogContent from "@material-ui/core/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { isOpenVideo, selectVideoFiles, selectVideoLink, setOpenVideo } from "./userDetailsSlice";

const useStyles = makeStyles((theme) => ({
  videoBox: {
    position: "relative",
  },
  closeIcon: {
    color: "#484848",
    "&:hover": {
      color: "red",
      cursor: "pointer",
    },
  },
  manyVideosBox: {
    display: "flex",
    flexDirection: "column"
  }
}));

const VideoModal = () => {
  const classes = useStyles();
  const isOpen = useSelector(isOpenVideo);
  const dispatch = useDispatch();
  const videoUrl = useSelector(selectVideoLink);
  const videoFiles = useSelector(selectVideoFiles);

  const handleClose = () => {
    dispatch(setOpenVideo(false));
  };

  const ManyVideos = () => {
    return <>
      {/* sorting by main flag so the main video will be 1st*/}
      {videoFiles.map(({ filename }, index) => {
        return <div className={classes.manyVideosBox}>
          <ReactPlayer key={index} width={800} url={filename} controls />
        </div>
      })}
    </>
  }

  return (
    <div>
      <Dialog
        maxWidth={"lg"}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid lg={6} item>
              <Typography variant="h5">Видео</Typography>
            </Grid>
            <Grid style={{ textAlign: "right" }} lg={5} item>
              <Cancel onClick={handleClose} className={classes.closeIcon} />
            </Grid>
          </Grid>

          <div className={classes.videoBox}>
            {videoFiles && videoFiles.length
              ? <ManyVideos />
              : <ReactPlayer width={800} url={videoUrl} controls />
            }
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoModal;
