import axios from 'axios/index';
import { DELETE_ID_CARD_IMAGE } from '../../utils/constants';

const deleteIDCard = async (hash, id) => {

    return await axios.delete(DELETE_ID_CARD_IMAGE,
        { // <= id here
            data: { id: id },
            headers: {
                "Accept": "application/json",
                "Accept-language": "bg",
                "Authorization": hash,
            }
        }).then(resp => {
            return resp;
        }).catch(err => {
            console.error(err)
        })
}

export default deleteIDCard;