import axios from "axios";
import { FORMS, BENEFICIAL_OWNERS_TYPE, PEP_DECLARATION_SIGNATURE_FORM } from "../../utils/constants";

export const getBeneficialOwners = async (identifyToken, hash) => {

    const resp = await axios.get(FORMS(identifyToken, BENEFICIAL_OWNERS_TYPE), {
        responseType: "blob",
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash
        }
    })

    return resp
}

export const getPepDeclaration = async (identifyToken, hash) => {

    const resp = await axios.get(FORMS(identifyToken, PEP_DECLARATION_SIGNATURE_FORM), {
        responseType: "blob",
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash
        }
    })

    return resp;
}