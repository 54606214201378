import React, { useContext, useState } from "react";
import updateAgentUi from "../../services/PUT/updateAgentUi";
import { makeStyles } from "@material-ui/styles";
import MainContext from "../../context/MainContext";
import {
  TextField,
  Box,
  Button,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import setConsentPdf from "../../services/PUT/setConsentPdf";
import { Dropzone, FileItem } from "dropzone-ui";
import { Link } from "react-router-dom";
import RenderOnModerator from "../../SecuredRoutes/RenderOnModerator";
import RenderOnAgent from "../../SecuredRoutes/RenderOnAgent";
import { deleteConsent } from "../../services/DELETE/deleteConsent";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    textAlign: "left",
    padding: 20,
    marginTop: 20,
  },
}));

const Consent = ({ data, index, getUi }) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const { consents, agentUi, selectedAgentID } = context;
  const [pdf, setPdf] = useState([]);
  const hash = localStorage.getItem("hash");

  const handleDelete = async () => {
    await deleteConsent(data.id);
    getUi();
  };

  const updateFiles = (incommingFiles) => {
    if (!pdf.length) {
      setPdf(incommingFiles);
    }
  };
  const onDelete = () => {
    setPdf([]);
  };

  const handleUpdate = async () => {
    const status = await updateAgentUi(
      agentUi,
      selectedAgentID,
      hash,
      consents
    );
    if (status === 200) {
      getUi();
    }
  };

  const prepareForUpdate =
    (id, property, index) =>
    ({ target }) => {
      const value = target.value;
      const temp = consents.find((con) => con.id === id);
      temp[property] = value;
      consents[index] = temp;
      context.addConsents(consents);
    };

  const handleSubmit = (id) => async (e) => {
    const form = new FormData();
    form.append("file", pdf[0].file);
    const status = await setConsentPdf(hash, id, form);
    if (status === 200) {
      getUi();
    }
    setPdf([]);
  };

  return (
    <div>
      <Box boxShadow={4} key={`${index}-${data.id}`} className={classes.root}>
        <Grid container justifyContent="space-between" alignContent="center">
          <Grid item>
            <Typography paragraph>
              Създаден:{" "}
              {format(new Date(data.dateCreated), "dd/MM/yyyy HH:mm:ss")}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleDelete}
              variant="contained"
              color="secondary"
            >
              Изтрий
            </Button>
          </Grid>
        </Grid>

        <TextField
          onChange={prepareForUpdate(data.id, "label", index)}
          label="Лейбъл"
          variant="outlined"
          fullWidth={true}
          margin="dense"
          defaultValue={data.label}
        />

        <Grid container alignItems="center">
          <Grid item lg={6}>
            <TextField
              onChange={prepareForUpdate(data.id, "lng", index)}
              className={classes.lang}
              label="Език"
              variant="outlined"
              margin="dense"
              defaultValue={data.lng}
            />
          </Grid>
          <Grid item>
            <RenderOnModerator>
              <Button onClick={handleUpdate} color="primary" variant="outlined">
                {" "}
                Запази
              </Button>
            </RenderOnModerator>
          </Grid>
        </Grid>

        <Divider />
        <Divider />
        <Divider />
        <Divider />
        <Grid
          style={{ marginTop: 20 }}
          container
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid lg={6} item>
            <Dropzone
              onChange={updateFiles}
              value={pdf}
              label="Качи нов PDF"
              header={false}
              accept="application/pdf"
              maxFiles={1}
              minHeight="200px"
            >
              {pdf.map((file, i) => (
                <FileItem
                  key={i}
                  {...file}
                  onDelete={onDelete}
                  //localization={"EN-en"}
                  info
                />
              ))}
            </Dropzone>
          </Grid>
          <Grid item lg={2}>
            {!!pdf.length && (
              <Button
                onClick={handleSubmit(data.id)}
                color="primary"
                variant="outlined"
              >
                Приложи
              </Button>
            )}
          </Grid>
          <Grid lg={3} item>
            <RenderOnAgent>
              <Link to={{ pathname: `/preview-pdf`, search: data.detailsUrl }}>
                Прегед на текущия
              </Link>
            </RenderOnAgent>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Consent;
