import Container from "@material-ui/core/Container";
import React, { useContext } from "react";
import MainContext from "../../context/MainContext";
import Navigation from "../basic/Navigation";
import ModalCompany from "../main/ModalCompany";

const MainLayout = (props) => {
  const user = useContext(MainContext).user;

  return (
    <>
      <Navigation />
      <Container  style={{maxWidth:1370}} maxWidth={false} >{props.children}</Container>
      {user && <ModalCompany />}
    </>
  );
};

export default MainLayout;
