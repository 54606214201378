import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Typography } from "@material-ui/core";
import setRiskFunc from "../../services/PUT/setRisk";
import { useDispatch } from "react-redux";
import {
  selectCurrentUser,
  setOpenComments,
  setOpenHistory,
} from "./userDetailsSlice";
import { useSelector } from "react-redux";
import OpenModalIcon from "@material-ui/icons/CallMade";
import CompanyOwners from "../main/CompanyOwners";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    justifyItems: "center",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "medium",
    minHeight: 27,
  },
  paperClickable: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "medium",
    cursor: "pointer",
    minHeight: 27,

    "&:hover": {
      background: "lightGray",
    },
  },
  select: {
    "& .MuiSelect-root": {
      paddingTop: 6,
      paddingBottom: 6,
      width: 60,
    },
  },
}));

const UserInfo = ({ hash, token }) => {
  const classes = useStyles();
  const selectedUser = useSelector(selectCurrentUser);
  const { firstName, fatherName } = selectedUser;
  const [risk, setRisk] = useState(selectedUser.risk || "");
  const dispatch = useDispatch();

  const show = (key) => () => {
    if (key === "comments") {
      dispatch(setOpenComments(true));
    }
    if (key === "history") {
      dispatch(setOpenHistory(true));
    }
  };

  const handleChange = () => (event) => {
    setRisk(event.target.value);
    setRiskFunc(event.target.value, hash, token);
  };

  return (
    <>
      <Grid
        className={classes.titleBox}
        container
        alignItems="center"
        justifyContent="space-evenly"
        spacing={1}
      >
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {!firstName && !fatherName ? (
              <Typography>
                <i> Непрочетени</i>
              </Typography>
            ) : (
              <>
                <Typography align="left"> Име: {firstName}</Typography>
                <Typography align="left">Фамилия: {fatherName}</Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper onClick={show("comments")} className={classes.paperClickable}>
            <Typography>
              {" "}
              Коментари <OpenModalIcon color="action" fontSize="small" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper onClick={show("history")} className={classes.paperClickable}>
            <Typography>
              История <OpenModalIcon color="action" fontSize="small" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography>
              Одобрение:
              {selectedUser.verificationStatus === "AUTOMATIC"
                ? " Автоматично"
                : selectedUser.verificationStatus === "PENDING"
                  ? " Чакащо"
                  : " Ръчно"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Typography>Риск:</Typography>
              <Select
                className={classes.select}
                variant="outlined"
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={risk}
                onChange={handleChange()}
                defaultValue=""
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="LOW">Нисък</MenuItem>
                <MenuItem value="MEDIUM">Среден</MenuItem>
                <MenuItem value="HIGH">Висок</MenuItem>
              </Select>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <CompanyOwners hash={hash} companyToken={token} />
    </>
  );
};

export default UserInfo;
