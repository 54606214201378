import axios from 'axios/index';
import { UPLOAD_PDF } from '../../utils/constants';

const setConsentPdf = (hash, uiConsentId, form) => {

    return axios.put(UPLOAD_PDF(uiConsentId),
        form,
        {
            headers: {
                "Accept": "application/json",
                "Accept-language": "bg",
                "Authorization": hash,
                "Content-Type": "multipart/form-data"
            }
        }
    ).then(resp => {
        return resp.status;
    }).catch(err => {
        console.error(err)
    })
}

export default setConsentPdf;