import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Divider, Grid } from "@material-ui/core";
import { useContext } from "react";
import MainContext from "../../context/MainContext";
import getCompanyByToken from "../../services/GET/getCompanyByToken";
import Loading from "../basic/LoadingTable";
import { useHistory } from "react-router";
// import CompanyOwners from "./CompanyOwners";
import CompanyHistory from "./CompanyHistory";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 100,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    borderRadius: 10,
    width: "50vw",
  },
  box: {
    cursor: "pointer",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    opacity: 0.7,
    width: "80%",
    paddingLeft: "10%",
  },
  subTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  divide: {
    marginTop: 10,
    marginBottom: 10,
  },
  alert: {
    "& .MuiAlert-icon": {
      fontSize: 0,
    },
    display: "flex",
    justifyContent: "center",
    fontSize: "large",
    padding: 0,
  },
  close: {
    cursor: "pointer",
    marginLeft: 20,
    "&:hover": {
      color: "red",
    },
  },
  FAILED: {
    background: "#eca1a6",
  },
  PENDING: {
    background: "orange",
  },
  SUCCESS: {
    background: "#b5e7a0",
  },
  STARTED: {
    background: "#e3eaa7",
  },
  missing: {
    background: "lightGray",
  },
  identification: {
    marginTop: 10,
    cursor: "pointer",
    textAlign: "center",
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
    "&:hover": {
      opacity: 0.8,
    },
  },
  header: {
    margin: "auto",
  },
  infoBox: {
    paddingInline: theme.spacing(4),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const ModalCompany = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open1);
  const [company, setCompany] = useState(null);
  const context = useContext(MainContext);
  const history = useHistory();
  const hash = context.user.hash;
  const [localLoading, setLocalLoading] = useState(false);
  const isOpen = context.companyModal.isOpen;
  const companyToken = context.companyModal.companyToken;

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      setLocalLoading(true);
      getCompanyByToken(companyToken, hash).then((resp) => {
        setCompany({
          ...resp,
          // partners: ["dsdsd", "skjdsk"],
          // directors: [1, 2, 3],
          // representatives: [34, 56],
          // managers: [23, 56, 8],
        });
        setLocalLoading(false);
      });
    }
    /* eslint-disable */
  }, [open]);

  const handleClose = () => {
    context.openCompanyModal(false, "");
  };

  const openIdentification = (token) => () => {
    history.push(`/details/${token}`);
    context.openCompanyModal(false, "");
  };
  if (localLoading || !company) {
    return (
      <div>
        <Modal open={localLoading}>
          <Loading />
        </Modal>
      </div>
    );
  }

  return (
    <div className={classes.box}>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid alignItems="center" container>
              <Grid item lg={11} md={11} sm={10} className={classes.title}>
                <Typography variant="h5">{company.name}</Typography>
              </Grid>
              <Grid item className={classes.close} onClick={handleClose}>
                <HighlightOffIcon />
              </Grid>
            </Grid>

            <Typography className={classes.subTitle} variant="h6">
              <b></b> Данни от ТРРЮЛНЦ:
            </Typography>
            <div>
              <Grid className={classes.infoBox} container direction="column">
                <Grid item>
                  <Typography variant="body2">
                    <b>ЕИК:</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {company.uic || "няма данни"}
                  </Typography>
                </Grid>
                <Divider className={classes.divide} />
                <Grid item>
                  {company.representatives &&
                    company.representatives.length !== 0 && (
                      <>
                        {" "}
                        <Divider className={classes.divide} />
                        <Typography variant="body2">
                          <b>Представляващ:</b>{" "}
                        </Typography>
                        <Typography variant="subtitle1">
                          {company.representatives.join(", ")}
                        </Typography>
                      </>
                    )}
                </Grid>
                <Divider className={classes.divide} />
                <Grid item>
                  {" "}
                  <Typography variant="body2">
                    <b>Адрес на управление:</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {company.address || "няма данни"}
                  </Typography>
                </Grid>
                <Divider className={classes.divide} />
                <Grid item>
                  <Typography variant="body2">
                    <b>Предмет на дейност:</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {company.activity}
                  </Typography>
                </Grid>
                {/* <Divider className={classes.divide} /> */}
                {/* <Grid item>
                  <Typography variant="body2">
                    <b>Собственост:</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {"ще уточним какво се показва точно"}
                  </Typography>
                </Grid> */}

                {company.directors && company.directors.length !== 0 && (
                  <>
                    <Divider className={classes.divide} />
                    <Typography variant="body2">
                      <b>Управител:</b>{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {company.directors.join(", ")}
                    </Typography>
                  </>
                )}
                {company.partners && company.partners.length !== 0 && (
                  <>
                    {" "}
                    <Divider className={classes.divide} />
                    <Typography variant="body2">
                      <b>Съдружници:</b>{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {company.partners.join(", ")}
                    </Typography>
                  </>
                )}
                {company.owners && company.owners.length !== 0 && (
                  <>
                    {" "}
                    <Divider className={classes.divide} />
                    <Typography variant="body2">
                      <b>Действителни собственици:</b>{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {company.owners.join(", ")}
                    </Typography>
                  </>
                )}
                {company.managers && company.managers.length !== 0 && (
                  <>
                    {" "}
                    <Divider className={classes.divide} />
                    <Typography variant="body2">
                      <b>Съвет на директорите:</b>{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {company.managers.join(", ")}
                    </Typography>{" "}
                  </>
                )}
                {company.merchant && (
                  <>
                    {" "}
                    <Divider className={classes.divide} />
                    <Typography variant="body2">
                      <b> Едноличен собственик на капитала:</b>{" "}
                      {company.merchant && company.merchant}
                    </Typography>
                  </>
                )}

                {/* <Divider className={classes.divide} />*/}
                {/* <CompanyOwners hash={hash} companyToken={companyToken} />  */}
              </Grid>
            </div>
            {/* <Grid container alignItems="center">
              <Grid lg={6} item>
              </Grid>
              <Grid lg={6} item></Grid>
            </Grid> */}
            <CompanyHistory />
            <Typography className={classes.subTitle} variant="h6">
              Идентификации:
            </Typography>
            <div style={{ maxHeight: 300, overflowY: "scroll" }}>
              {company.identification
                ? company.identifications.map((identity, i) => {
                    const status = identity.status || "missing";
                    return (
                      <>
                        <Typography
                          onClick={openIdentification(identity.identityToken)}
                          className={[
                            classes[status],
                            classes.identification,
                          ].join(" ")}
                        >
                          {" "}
                          към {identity.name || <i>липсва</i>} (
                          {identity.role || ""}) Статус: {identity.status}
                        </Typography>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalCompany;
