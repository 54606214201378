import { createSlice } from "@reduxjs/toolkit";

export const TABLE_SLICE = "table-slice";

const initialState = {
  page: 0,
  size: 10,
  totalRows: 0,
  rows: [],
  filters: {
    risk: "",
    agentId: "",
    userType: "",
    nationality: "",
    sex: "",
    search: "",
  },
  dateFrom: null,
  dateTo: null,
  sortBy: [],
  creationType: "",
};

const tableSlice = createSlice({
  name: TABLE_SLICE,
  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSize: (state, { payload }) => {
      state.size = payload;
    },
    setRows: (state, { payload }) => {
      if (payload && payload.length) {
        state.rows = [...payload];
      } else {
        state.rows = [];
      }
    },
    setTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setFilters: (state, { payload }) => {
      const { filter, value } = payload;
      const temp = { ...state.filters };

      temp[[filter]] = value;

      state.filters = temp;
    },
    setDateFrom: (state, { payload }) => {
      state.dateFrom = payload;
    },

    setDateTo: (state, { payload }) => {
      state.dateTo = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setCreationType: (state, { payload }) => {
      state.creationType = payload;
    },
  },
});

export const selectPage = (state) => state[TABLE_SLICE].page;

export const selectRows = (state) => state[TABLE_SLICE].rows;

export const selectSize = (state) => state[TABLE_SLICE].size;

export const selectTotalRows = (state) => state[TABLE_SLICE].totalRows;

export const selectFilters = (state) => state[TABLE_SLICE].filters;

export const selectDateFrom = (state) => state[TABLE_SLICE].dateFrom;

export const selectDateTo = (state) => state[TABLE_SLICE].dateTo;

export const selectSortBy = (state) => state[TABLE_SLICE].sortBy;

export const selectCreationType = (state) => state[TABLE_SLICE].creationType;

export const {
  setPage,
  setRows,
  setSize,
  setTotalRows,
  setFilters,
  setDateFrom,
  setDateTo,
  setSortBy,
  setCreationType
} = tableSlice.actions;

export default tableSlice.reducer;
