import axios from 'axios/index';
import { DOWNLOAD_OWNER_PEP, GET_COMPANY_OWNERS } from '../../utils/constants';

const getCompanyOwners = async (hash, companyToken) => { // queries === object

    return await axios.get(GET_COMPANY_OWNERS(companyToken), {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "authorization": hash,
        }
    }).then(resp => {
        return resp.data;
    }).catch(err => {
        console.error(err)
    })

}

export const downloadOwnerPep = async (hash, companyToken, index) => { // queries === object

    return await axios.get(DOWNLOAD_OWNER_PEP(companyToken, index), {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "authorization": hash,
        },
        responseType: 'blob', // important
    }).then(resp => {
        console.log(resp)
        return resp.data;
    }).catch(err => {
        console.error(err)
    })

}

export default getCompanyOwners