import React from "react";
import { Formik } from "formik";
import { Button, Divider, Input, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import setUserData from "../../services/PUT/setUserData";
import regixCheck from "../../services/GET/regixCheck";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "./userDetailsSlice";

const useStyles = makeStyles((theme) => ({
  formBox: {
    marginTop: theme.spacing(4),
    display: "block",
    margin: 15,
  },
  submitBtn: {
    margin: theme.spacing(2),
    marginLeft: "50%",
  },
  inputBox: {
    display: "flex",
    justifyContent: "space-between",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  errorInput: {
    border: "1px red solid",
    color: "red",
  },
  dividerBold: {
    background: "black",
    marginBlockStart: 35,
    marginBlockEnd: 35,
  },
}));

const inputs = [
  { label: "Име", type: "text", name: "name", value: "name" },
  { label: "Презиме", type: "text", name: "middleName", value: "middleName" },
  { label: "Фамилия", type: "text", name: "lastName", value: "lastName" },
  { label: "ЕГН", type: "number", name: "egn", value: "egn" },
  {
    label: "Дата на раждане",
    type: "date",
    name: "dateOfBirth",
    value: "dateOfBirth",
  },
  {
    label: "№ на документ",
    type: "number",
    name: "documentNumber",
    value: "documentNumber",
  },
  { label: "Валидност", type: "date", name: "expiring", value: "expiring" },
  {
    label: "Гражданство",
    type: "text",
    name: "nationality",
    value: "nationality",
  },
  {
    label: "Пол",
    type: "text",
    name: "sex",
    value: "sex",
  },
  {
    label: "Email",
    type: "text",
    name: "email",
    value: "email",
  },
  {
    label: "Телефон",
    type: "tel",
    name: "phone",
    value: "phone",
  },
];

const UserDataForm = ({ hash, token, getUser }) => {
  const classes = useStyles();
  const selectedUser = useSelector(selectCurrentUser);

  const checkInRegix = async () => {
    await regixCheck(token, hash);
    await getUser();
  };

  const {
    dateOfBirth,
    dateOfExpiry,
    documentNumber,
    email,
    fatherName,
    firstName,
    personalNumber,
    phoneNumber,
    residence,
    sex,
    surname,
    nationality,
  } = selectedUser;

  const canCheck = documentNumber && personalNumber;

  const initialValues = {
    lastName: fatherName || "",
    name: firstName || "",
    middleName: surname || "",
    egn: personalNumber || "",
    dateOfBirth:
      dateOfBirth !== null
        ? new Date(dateOfBirth).toISOString().substring(0, 10)
        : "",
    documentNumber: documentNumber || "",
    expiring:
      dateOfExpiry !== null
        ? new Date(dateOfExpiry).toISOString().substring(0, 10)
        : "",
    citizenship: residence || "",
    sex: sex || "",
    email: email || "",
    phone: phoneNumber || "",
    nationality: nationality || "",
  };

  return (
    <div className={classes.formBox}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};

          if (!values.profession) {
            errors.profession = " Задължително";
          }
          if (!values.position) {
            errors.position = " Задължително";
          }
          if (!values.employer) {
            errors.employer = " Задължително";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const { profession, position, employer } = values;
          setUserData({ employer, position, profession }, hash, token);
          setSubmitting(false);
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Button
              className={classes.submitBtn}
              variant="contained"
              color="primary"
              type="submit"
              disabled={!canCheck}
              onClick={checkInRegix}
            >
              Проверка в Regix
            </Button>
            <Divider />

            {inputs.map(({ label, type, name, value }, index) => {
              return (
                <div key={`${label}-${index}`}>
                  {name === "profession" ? (
                    <Divider className={classes.dividerBold} />
                  ) : null}
                  <div className={classes.inputBox}>
                    <Typography>{label}</Typography>
                    <Input
                      placeholder={errors[name]}
                      className={!errors[name] || classes.errorInput}
                      type={type}
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[value]}
                    />
                  </div>
                  <Divider />
                </div>
              );
            })}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserDataForm;
