import axios from 'axios/index';
import { FORMAT_DATE_FILTER, GET_COMPANIES } from '../../utils/constants';

const getCompanies = async (queries, hash, fromDate, toDate, sortBy) => { // queries === object
    const q = new URLSearchParams();

    if (sortBy.length && sortBy[0].field === "dateCreated") {
        q.append("sortType", sortBy[0].sort)
        q.append("sort", sortBy[0].field)
    }

    q.append("page", queries.page);
    q.append("size", queries.size);

    if (fromDate && fromDate.length) q.append("start", FORMAT_DATE_FILTER(fromDate))
    if (fromDate && fromDate.length && toDate && toDate.length) q.append("end", FORMAT_DATE_FILTER(toDate))

    if (queries.search && queries.search.length) q.append("search", queries.search);

    return await axios.get(GET_COMPANIES(q), {

        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        }

    }).then(resp => {
        const { elements: totalRows } = resp.data
        const respRows = resp.data.data;
        return {
            totalRows, respRows
        }
    }).catch(err => {
        console.error(err)
    })

}

export default getCompanies