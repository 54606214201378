import axios from 'axios/index';
import { ADD_COMMENT } from '../../utils/constants';

const addComment = async (comment, hash, token) => {
    return await axios.post(ADD_COMMENT(token),
        { comment: comment }, { // <= email,password
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        }
    }).then(resp => {
        return resp.data;
    }).catch(err => {
        console.error(err)
    })
}

export default addComment;