import React, { useEffect, useContext, useState } from "react";
import MainContext from "../context/MainContext";
import getAgents from "../services/GET/getAgents";
import LoadingTable from "../components/basic/LoadingTable";
import Agent from "../components/agents/Agent";
import { Typography } from "@material-ui/core";
import CreateAgent from "../components/agents/CreateAgent";

const AgentsPanel = () => {
  const context = useContext(MainContext);
  const hash = context.user?.hash;
  const [currentAgents, setCurrentAgents] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    setLocalLoading(true);
    if (hash) getAllAgents().then(() => setLocalLoading(false));
    // eslint-disable-next-line
  }, []);

  const getAllAgents = async () => {
    const agents = await getAgents(hash);
    setCurrentAgents(agents);
  };

  if (localLoading) {
    return <LoadingTable />;
  }

  return (
    <div>
      <CreateAgent updateAgents={getAllAgents} />
      <Typography variant="h4" align="center">
        {" "}
        Агенти
      </Typography>
      {currentAgents.map((agent, i) => (
        <Agent
          key={`${agent.name}`}
          agent={agent}
          hash={hash}
          updateAgents={getAllAgents}
        />
      ))}
    </div>
  );
};

export default AgentsPanel;
