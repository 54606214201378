import { makeStyles } from "@material-ui/core/styles";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import CancelIcon from "@material-ui/icons/Cancel";
import getCompanies from "../../services/GET/getCompanies";
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useContext } from "react";
import MainContext from "../../context/MainContext";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPage,
  selectSize,
  selectFilters,
  selectDateFrom,
  selectDateTo,
  selectTotalRows,
  setSize,
  setTotalRows,
  setPage,
  setRows,
  selectRows,
  setSortBy,
  selectSortBy,
} from "./tableSlice";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  completed: {
    color: "#29BE55",
  },
  notCompleted: {
    color: "#FF4245",
  },
});

const splitLongAddress = (address) => {
  if (!address) {
    return [""];
  }

  const arr = address.split(" ");

  if (arr.length < 6) {
    return address;
  }

  const part1 = arr.slice(0, 7);
  const part2 = arr.slice(7);

  return [part1, part2];
};

const IsFinished = ({ params }) => {
  const classes = useStyles();
  return (
    <div>
      {" "}
      {params.row.isFinished ? (
        <CheckCircleSharpIcon className={classes.completed} />
      ) : (
        <CancelIcon className={classes.notCompleted} />
      )}
    </div>
  );
};
const columns = [
  {
    field: "dateCreated",
    headerName: "Добавена",
    width: 190,
    align: "left",
    renderCell: (params) => (
      <Typography style={{ cursor: "pointer" }} variant="subtitle2">
        {params.row.dateCreated.slice(0, 19).replace("T", " ")}
      </Typography>
    ),
  },
  {
    field: "eik",
    headerName: "ЕИК",
    width: 130,
    align: "left",
    renderCell: (params) => (
      <Typography style={{ cursor: "pointer" }} variant="subtitle2">
        {params.row.eik}
      </Typography>
    ),
  },
  {
    field: "name",
    headerName: "Име",
    width: 220,
    align: "left",
    renderCell: (params) => (
      <Typography style={{ cursor: "pointer" }} variant="subtitle2">
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: "address",
    headerName: "Адрес",
    width: 470,
    align: "left",
    renderCell: (params) => {
      const addressArr = splitLongAddress(params.row.address);
      return (
        <div>
          {addressArr.map((line) => {
            if (typeof line === "string") {
              return (
                <Typography style={{ cursor: "pointer" }} variant="subtitle2">
                  {line}
                </Typography>
              );
            }
            return (
              <Typography style={{ cursor: "pointer" }} variant="subtitle2">
                {line.join(" ")}
              </Typography>
            );
          })}
        </div>
      );
    },
  },
  {
    field: "isFinished",
    headerName: "Завършена",
    width: 150,
    align: "center",
    renderCell: (params) => {
      return <IsFinished params={params} />;
    },
  },
];

const TableWaiting = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const context = useContext(MainContext);
  const hash = context.user.hash;
  const page = useSelector(selectPage);
  const size = useSelector(selectSize);
  const { search } = useSelector(selectFilters);
  const fromDate = useSelector(selectDateFrom);
  const toDate = useSelector(selectDateTo);
  const totalRows = useSelector(selectTotalRows);
  const rows = useSelector(selectRows);
  const sortBy = useSelector(selectSortBy);

  useEffect(() => {
    dispatch(setRows([]));
    setLoading(true);
    getCompanies({ size, page, search }, hash, fromDate, toDate, sortBy).then(
      (resp) => {
        if (resp) {
          dispatch(setRows(resp.respRows));
          dispatch(setTotalRows(resp.totalRows));
          setLoading(false);
        }
      }
    );
    // eslint-disable-next-line
  }, [context, hash, page, size, search, fromDate, toDate, sortBy]);

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch(setPage(0));
    dispatch(setSize(newSize));
  };

  const handleClick =
    () =>
    ({ row }) => {
      context.openCompanyModal(true, row.identityToken);
    };

  const handleSorting = (model) => {
    if (JSON.stringify(sortBy) !== JSON.stringify(model)) {
      dispatch(setRows([]));
      dispatch(setSortBy(model));
    }
  };

  return (
    <div>
      <DataGrid
        autoHeight
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Няма записи
            </div>
          ),
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Редове на страница",
          },
        }}
        pagination
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
        onCellClick={handleClick()}
        rows={rows}
        pageSize={size}
        page={page}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        loading={loading}
        onPageChange={handleChangePage}
        rowCount={totalRows}
        paginationMode="server"
        columns={columns.map((column) => ({
          ...column,
          sortable: true,
        }))}
        onSortModelChange={handleSorting}
      />
    </div>
  );
};

export default TableWaiting;
