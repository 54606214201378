import React, { useContext } from "react";
import MainContext from "../context/MainContext";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = (props) => {
  const context = useContext(MainContext);
  const canSee = !context.user?.roles.includes("agent"); // moderator agent
  return canSee ? <Route {...props} /> : <Redirect to="/home" />;
};

export default ProtectedRoute;
