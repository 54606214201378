import axios from 'axios/index';
import { GET_AGENT_UI } from '../../utils/constants';

const getAgentUi = async (agentId, hash) => { // queries === object

    return await axios.get(GET_AGENT_UI(agentId), {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/pdf",
            "Accept-language": "bg",
            "Authorization": hash,
        }
    }).then(resp => {
        return resp.data;
    }).catch(err => {
        console.error(err)
    })

}

export default getAgentUi