import { createSlice } from "@reduxjs/toolkit";

export const USER_DETAILS = 'user-details';

const initialState = {
    userCommentsOpen: false,
    userHistoryOpen: false,
    selectedUser: {},
    photosModal: {
        photosType: "",
        isOpen: false
    },
    videoOpen: false,
    videoLink: null,
    videoFiles: []
    // some states for photo galleries goes here and above
}

const userDetailsSlice = createSlice({
    name: USER_DETAILS,
    initialState,
    reducers: {
        setOpenComments(state, { payload }) {
            state.userCommentsOpen = payload;
        },
        setOpenHistory(state, { payload }) {
            state.userHistoryOpen = payload;
        },
        setCurrentUser(state, { payload }) {
            state.selectedUser = payload
        },
        setPhotosModal(state, { payload }) {
            state.photosModal = payload
        },
        setOpenVideo(state, { payload }) {
            state.videoOpen = payload
        },
        setVideoLink: (state, { payload }) => {
            state.videoLink = payload;
        },
        setVideoFiles: (state, { payload }) => {
            state.videoFiles = [...payload]
        }
    }
})

export const isOpenHistory = (state) => state[USER_DETAILS].userHistoryOpen;

export const isOpenComments = (state) => state[USER_DETAILS].userCommentsOpen;

export const selectCurrentUser = (state) => state[USER_DETAILS].selectedUser;

export const selectPhotosModal = (state) => state[USER_DETAILS].photosModal;

export const isOpenVideo = (state) => state[USER_DETAILS].videoOpen;

export const selectVideoLink = (state) => state[USER_DETAILS].videoLink;

export const selectVideoFiles = (state) => state[USER_DETAILS].videoFiles;

export const {
    setOpenComments,
    setOpenHistory,
    setCurrentUser,
    setPhotosModal,
    setOpenVideo,
    setVideoLink,
    setVideoFiles
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;