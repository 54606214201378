import React, { useContext } from "react";
import MainContext from "../context/MainContext";

const RenderOnAgentModerator = ({ children }) => {
  const context = useContext(MainContext);
  const roles = context.user.roles;
  const haveAccess = roles.includes("moderator_agent");
  return <div> {haveAccess ? children : null}</div>;
};

export default RenderOnAgentModerator;
