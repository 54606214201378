import React, { useState, useContext, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import setLogo from "../../services/PUT/setLogo";
import MainContext from "../../context/MainContext";
import { makeStyles } from "@material-ui/styles";
import { Dropzone, FileItem, FullScreenPreview } from "dropzone-ui";
import { getBase64, testImage } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
  imageBox: {
    width: 200,
    height: 150,
    padding: 30,
    border: "2px lightGray solid",
    position: "relative",
    margin: "auto",
    marginBottom: 50,
  },
  imagePreview: {
    maxWidth: 200,
    margin: "auto",
  },
  btn: {
    margin: 20,
  },
}));

const UploadLogo = ({ getUi }) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const [logo, setFile] = useState([]);
  const hash = localStorage.getItem("hash");
  const uiId = context.agentUi.id;
  const [oldLogo, setOldLogo] = useState("");
  const [imageSrc, setImageSrc] = useState(undefined);

  const handleSubmit = () => (e) => {
    const form = new FormData();
    form.append("file", logo[0].file);
    setLogo(hash, uiId, form).then(async () => {
      console.log("uploaded");
      getBase64(logo[0].file, (x) => setOldLogo(x));
      setFile([]);
    });
  };

  const updateFiles = (incomingFiles) => {
    if (!logo.length) {
      setFile(incomingFiles);
    } else {
      setFile([]);
    }
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const onDelete = () => {
    setFile([]);
  };

  useEffect(() => {
    const setImage = (isValid) => {
      if (!isValid) {
        setOldLogo("images/logo_default.png");
      } else {
        setOldLogo(context.agentUi.logoUrl);
      }
    };
    testImage(context.agentUi.logoUrl, setImage);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Typography>Текущо лого</Typography>
      <div className={classes.imageBox}>
        <img
          alt="logo"
          // onError={"/images/logo_default"}
          className={classes.imagePreview}
          src={oldLogo}
        />
      </div>
      <Dropzone
        onChange={updateFiles}
        value={logo}
        maxFiles={10}
        header={false}
        minHeight="200px"
        label="Качи ново Лого"
        accept=".png"
        maxFileSize={5998000}
      >
        {logo.map((file, i) => (
          <FileItem
            key={i}
            {...file}
            onDelete={onDelete}
            onSee={handleSee}
            preview
            info
            hd
          />
        ))}
        <FullScreenPreview
          imgSource={imageSrc}
          openImage={imageSrc}
          onClose={(e) => handleSee(undefined)}
        />
      </Dropzone>

      {!!logo.length && (
        <Button
          className={classes.btn}
          color="primary"
          variant="outlined"
          onClick={handleSubmit()}
        >
          Качи
        </Button>
      )}
    </div>
  );
};

export default UploadLogo;
