import {
  Box,
  Grid,
  TextField,
  Card,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import MainContext from "../../context/MainContext";
import addAgent from "../../services/POST/addAgent";

const CreateAgent = ({ updateAgents }) => {
  const [name, setName] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);
  const context = useContext(MainContext);

  const hash = context.user.hash;

  useEffect(() => {
    if (name.length) setCanSubmit(false);
  }, [name]);

  const handleSubmit = () => {
    addAndUpdate();
  };

  const addAndUpdate = async () => {
    await addAgent({ name }, hash);
    updateAgents();
  };

  return (
    <div>
      <Box
        sx={{
          boxShadow: 1,
          borderRadius: 1,
          pt: 2,
          minWidth: 300,
        }}
      >
        <Card>
          <CardContent>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item>
                <Typography variant="h5">Създай Агент</Typography>
              </Grid>
              <Grid item>
                <TextField
                  required
                  key={1}
                  label="Име"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={canSubmit}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Създай
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default CreateAgent;
