import axios from "axios/index";
import { ADD_IDENTIFICATION } from "../../utils/constants";


export const addIdentification = async ({
  agentId,
  documentNumber,
  personalNumber,
  uic,
  hash,
}) => {
  const body = {
    agentId: +agentId,
    documentNumber,
    personalNumber,
  };

  if (uic.length) {
    body["uic"] = uic;
  }

  return await axios.post(ADD_IDENTIFICATION, body, {
    headers: {
      Authorization: hash,
    },
  });
};
