import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableUsers from "./TableUsers";
import TableCompanies from "./TableCompanies";
import { useDispatch, useSelector } from "react-redux";
import { selectTab, setTab } from "./tabsSlice";
import { setPage } from "./tableSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    marginTop: "5vh",
    flexGrow: 1,
    width: "100%",
  },
  tabs: {
    boxShadow: "none",
    borderBottom: "1px lightGray solid",
  },
  lastTab: {
    marginLeft: "auto",
    textTransform: "none",
  },
  tab: {
    textTransform: "none",
  },
});

const TabsPanel = () => {
  const classes = useStyles();
  // eslint-disable-next-line
  const openTab = useSelector(selectTab);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setPage(0));
    dispatch(setTab(newValue));
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.tabs} position="static" color="inherit">
        <Tabs
          value={openTab}
          onChange={handleChange}
          indicatorColor="primary"
          // textColor="#00000a"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab className={classes.tab} label="Чакащи" {...a11yProps(0)} />
          <Tab className={classes.tab} label="Потвърдени" {...a11yProps(1)} />
          <Tab className={classes.tab} label="Отхвърлени" {...a11yProps(2)} />
          <Tab className={classes.tab} label="Незавършени" {...a11yProps(3)} />
          <Tab className={classes.tab} label="Изтекли" {...a11yProps(4)} />
          <Tab className={classes.lastTab} label="Фирми" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={openTab} index={0}>
        <TableUsers status={"PENDING"} />
      </TabPanel>
      <TabPanel value={openTab} index={1}>
        <TableUsers status={"SUCCESS"} />
      </TabPanel>
      <TabPanel value={openTab} index={2}>
        <TableUsers status={"FAILED"} />
      </TabPanel>
      <TabPanel value={openTab} index={3}>
        <TableUsers status={"STARTED"} />
      </TabPanel>
      <TabPanel value={openTab} index={4}>
        <TableUsers status={"INVALID"} />
      </TabPanel>
      <TabPanel value={openTab} index={5}>
        <TableCompanies />
      </TabPanel>
    </div>
  );
};

export default TabsPanel;
