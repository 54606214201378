import React from 'react';
import ReactDOM from 'react-dom';
import PageRouter from './PageRouter';
// import HttpService from './SecuredRoutes/HttpService';

import UserService from './SecuredRoutes/UserService';

// alert(window.location.hash)

UserService.initKeycloak(() => ReactDOM.render(<PageRouter />, document.getElementById('root')));
// HttpService.configure();