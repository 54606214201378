import axios from 'axios/index';
import { REGIX_CHECK } from '../../utils/constants';

const regixCheck = async (hash, egn) => {

    return await axios.get(REGIX_CHECK(egn),
        {
            headers: {
                "Accept": "application/json",
                "Accept-language": "bg",
                "Authorization": hash,
            }
        }).then(resp => {
            return resp;
        }).catch(err => {
            console.error(err)
        })
}

export default regixCheck;