import Filters from "../components/main/Filters";
import Tabs from "../components/main/Tabs";

const MainPage = () => {
  console.log("29-03-2022");
  return (
    <div>
      <Filters />
      <Tabs />
    </div>
  );
};

export default MainPage;
