import axios from 'axios/index';
import { UPDATE_ID_CARD_IMAGE } from '../../utils/constants';

const setMainIDCard = async (hash, id, isMain) => {

    return await axios.put(UPDATE_ID_CARD_IMAGE,
        // <= id here
        { id: id, main: isMain },
        {
            headers: {
                "Accept": "application/json",
                "Accept-language": "bg",
                "Authorization": hash,
            }
        }
    ).then(resp => {
        return resp;
    }).catch(err => {
        console.error(err)
    })
}

export default setMainIDCard;