import axios from "axios/index";
import { UPLOAD_IMAGE } from "../../utils/constants";

export const uploadImage = ({ base64img, identityToken, type, hash }) => {
  axios.post(
    UPLOAD_IMAGE,
    {
      base64img,
      identityToken,
      type,
    },
    {
      headers: {
        Authorization: hash,
      },
    }
  );
};
