import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBar from "material-ui-search-bar";
import { useSelector, useDispatch } from "react-redux";
import {
  selectDateFrom,
  selectDateTo,
  setDateFrom,
  setDateTo,
  selectFilters,
  setFilters,
  setPage,
  selectCreationType,
  setCreationType,
} from "./tableSlice";
import { FILTERS_TO_IGNORE } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "5vh",
    justifyContent: "space-between",
  },
  dateField: {
    marginRight: theme.spacing(2),
    width: 190,
  },
  selectFilters: {
    marginRight: 15,
    minWidth: 100,
  },
  searchBox: {
    marginTop: theme.spacing(3),
    marginLeft: "auto",
  },
  filters: {
    display: "flex",
    justifyContent: "flex-end",
    width: 700,
  },
}));

const DateSelect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const fromDate = useSelector(selectDateFrom);
  const toDate = useSelector(selectDateTo);
  const creationType = useSelector(selectCreationType);

  const { search, sex, nationality, userType, risk } = filters;

  const handleSelect = () => (event) => {
    const value = event.target.value;
    const key = event.target.name;

    if (!FILTERS_TO_IGNORE.includes(value)) {
      dispatch(setFilters({ filter: key, value }));
    } else {
      dispatch(setFilters({ filter: key, value: "" }));
    }
    dispatch(setPage(0));
  };

  const handleSearch = () => (value) => {
    dispatch(setPage(0));
    dispatch(setFilters({ filter: "search", value }));
  };

  const setFrom = (date) => {
    const stringDate = new Date(date).toString();
    dispatch(setDateFrom(stringDate));
  };

  const setTo = (date) => {
    const stringDate = new Date(date).toString();
    dispatch(setDateTo(stringDate));
  };

  const handleCreationType = ({ target: { value } }) => {
    if (value === "ONLINE" || value === "OFFLINE"){
      dispatch(setCreationType(value));
    }else{
      dispatch(setCreationType(""));
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <KeyboardDatePicker
          format="dd/MM/yyyy"
          value={fromDate}
          onChange={setFrom}
          className={classes.dateField}
          ampm={false}
          variant="inline"
          disableToolbar
          disableFuture
          autoOk
          label="От"
        />

        <KeyboardDatePicker
          format="dd/MM/yyyy"
          value={toDate}
          onChange={setTo}
          className={classes.dateField}
          ampm={false}
          variant="inline"
          disableToolbar
          disableFuture
          autoOk
          label="До"
        />
      </div>

      <div className={classes.filters}>
        <Select
          className={classes.selectFilters}
          name="creationType"
          value={creationType || "kyc"}
          onChange={handleCreationType}
        >
          <MenuItem value="kyc">KYC</MenuItem>
          <MenuItem value="ONLINE">Онлайн</MenuItem>
          <MenuItem value="OFFLINE">Офлайн</MenuItem>
        </Select>
        <Select
          className={classes.selectFilters}
          name="sex"
          value={sex || "sex"}
          onChange={handleSelect()}
        >
          <MenuItem value="sex">Пол</MenuItem>
          <MenuItem value="man">Мъж</MenuItem>
          <MenuItem value="woman">Жена</MenuItem>
        </Select>

        <Select
          className={classes.selectFilters}
          name="nationality"
          value={nationality || "nationality"}
          onChange={handleSelect()}
        >
          <MenuItem value="nationality">Гражданство</MenuItem>
          <MenuItem value="България">Българско</MenuItem>
          <MenuItem value="OTHER">Друго</MenuItem>
        </Select>

        <Select
          className={classes.selectFilters}
          name="userType"
          value={userType || "userType"}
          onChange={handleSelect()}
        >
          <MenuItem value="userType">Вид лице</MenuItem>
          <MenuItem value="COMPANY">Юридическо</MenuItem>
          <MenuItem value="USER">Физическо</MenuItem>
        </Select>

        {/* <Select
          className={classes.selectFilters}
          name="agentId"
          value={agent}
          defaultValue="agentId"
          onChange={handleSelect()}
        >
          <MenuItem value="agentId">Агент</MenuItem>
          <MenuItem value={1}>Агент 1</MenuItem>
          <MenuItem value={2}>Агент 2</MenuItem>
        </Select> */}

        <Select
          className={classes.selectFilters}
          name="risk"
          value={risk || "risk"}
          onChange={handleSelect()}
        >
          <MenuItem value="risk">Риск</MenuItem>
          <MenuItem value="LOW">Нисък</MenuItem>
          <MenuItem value="MEDIUM">Среден</MenuItem>
          <MenuItem value="HIGH">Висок</MenuItem>
        </Select>
      </div>
      <div className={classes.searchBox}>
        <SearchBar
          value={search}
          onChange={handleSearch()}
          onCancelSearch={handleSearch()}
          placeholder="Търсене..."
        />
      </div>
    </div>
  );
};

export default DateSelect;
