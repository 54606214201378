import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  page: {
    margin: 0,
  },
  paginationBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
}));

const PreviewPDF = ({ location }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(null);
  const pdfFile = location.search.replace("?", "");

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(numPages);
  };

  const handlePageChange = (val) => {
    setPage(val);
  };

  return (
    <div>
      <div className={classes.paginationBox}>
        <Pagination
          count={pageNumber}
          shape="rounded"
          defaultPage={1}
          onChange={(e, v) => handlePageChange(v)}
        />
      </div>
      <Document
        className={classes.root}
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          height={1200}
          className={classes.page}
          pageNumber={page}
          page={page}
        />
      </Document>
    </div>
  );
};

export default PreviewPDF;
