import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import MainContext from "../../context/MainContext";

// import iris_image from "../../static/iris.png";
import { Link } from "react-router-dom";
import UserService from "../../SecuredRoutes/UserService";
import RenderOnAgentModerator from "../../SecuredRoutes/RenderOnAgentModerator";
import RenderOnModerator from "../../SecuredRoutes/RenderOnModerator";
import OfflineKYCModal from "../main/OfflineKYCModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  userActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 96,
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
  offlineButton: {
    color: "#FFF",
    marginLeft: 20,
  },
}));

const Navigation = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedUser, setLoggedUser] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const context = useContext(MainContext);

  const { signOut } = context;

  useEffect(() => {
    setLoggedUser(context.user);
  }, [context]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const redirectTo = (location) => () => {
    setAnchorEl(null);
    history.push(location);
  };

  const toExit = () => () => {
    setAnchorEl(null);
    UserService.doLogout();
    signOut();
  };

  const handleClose = () => () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6">
              {/* {context.user !== null ? ( */}
              <Link className={classes.link} to="/home">
                <Typography variant="h6">HOME</Typography>
                {/* <img className={classes.logo} alt="logo" src={iris_image} /> */}
              </Link>
              {/* ) : ( */}
              {/* <img className={classes.logo} alt="logo" src={iris_image} /> */}
              {/* )} */}
            </Typography>
            <Link className={classes.link} style={{ marginLeft: 20 }} to="/regix">
              {/* <RenderOnModerator> */}
                <Typography variant="h6">REGIX</Typography>
              {/* </RenderOnModerator> */}
              {/* <img className={classes.logo} alt="logo" src={iris_image} /> */}
            </Link>
            <OfflineKYCModal />
          </div>

          {loggedUser !== null ? (
            <div className={classes.userActions}>
              <Typography component="p">{loggedUser.name}</Typography>
              <IconButton color="inherit">
                <AccountCircle />
              </IconButton>
              <IconButton onClick={handleMenu} color="inherit">
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose()}
              >
                <RenderOnAgentModerator>
                  <MenuItem onClick={redirectTo("/agents-listing")}>
                    Агенти
                  </MenuItem>
                </RenderOnAgentModerator>
                <RenderOnModerator>
                  <MenuItem onClick={redirectTo("/agents-listing")}>
                    Агенти
                  </MenuItem>
                </RenderOnModerator>
                <MenuItem onClick={toExit()}>Изход</MenuItem>
              </Menu>
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navigation;
