import React, { useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  OutlinedInput,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import setUserData from "../../services/PUT/setUserData";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "./userDetailsSlice";
import {
  getBeneficialOwners,
  getPepDeclaration,
} from "../../services/GET/getForms";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import CheckIcon from "@material-ui/icons/CheckSharp";
import NegativeIcon from "@material-ui/icons/ClearSharp";

const useStyles = makeStyles((theme) => ({
  formBox: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    margin: 15,
  },
  submitBtn: {
    display: "block",
    marginLeft: "auto",
    margin: theme.spacing(2),
  },
  inputBox: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  errorInput: {
    border: "1px red solid",
    color: "red",
    width: 300,
  },
  inputOk: {
    border: "1px transparent solid",

    width: 300,
  },
  dividerBold: {
    background: "black",
    marginBlockStart: 35,
    marginBlockEnd: 35,
  },
  rootForm: {
    width: "40%",
  },
  dialogActions: {
    minWidth: 300,
    display: "flex",
    justifyContent: "space-around",
  },
  forms: {
    maxHeight: 50,
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
  },
}));

const inputs = [
  {
    label: "Професия",
    type: "text",
    name: "profession",
    value: "profession",
  },
  {
    label: "Длъжност",
    type: "text",
    name: "position",
    value: "position",
  },
  {
    label: "Работодател",
    type: "text",
    name: "employer",
    value: "employer",
  },
];

const UserDetailsForm = ({ hash, token }) => {
  const classes = useStyles();
  const selectedUser = useSelector(selectCurrentUser);
  const [open, setOpen] = useState(false);
  const { employer, position, proffession, pepPassed, beneficialOwnersPassed,companyUserIdent } =
    selectedUser;

  const openPDF = (data) => {
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const downloadBeneficialOwners = async () => {
    const resp = await getBeneficialOwners(token, hash);
    openPDF(resp.data);
  };

  const downloadPepDeclaration = async () => {
    const resp = await getPepDeclaration(token, hash);
    openPDF(resp.data);
  };

  const initialValues = {
    profession: proffession || "",
    position: position || "",
    employer: employer || "",
  };

  const IsPassedIcon = ({ bool }) => {
    if (bool) {
      return <CheckIcon style={{ color: "green" }} />;
    }

    return <NegativeIcon style={{ color: "red" }} />;
  };

  return (
    <div className={classes.formBox}>
      <Formik
        initialValues={initialValues}
        // validate={(values) => {
        //   const errors = {};

        //   if (!values.profession) {
        //     errors.profession = " Задължително";
        //   }
        //   if (!values.position) {
        //     errors.position = " Задължително";
        //   }
        //   if (!values.employer) {
        //     errors.employer = " Задължително";
        //   }

        //   return errors;
        // }}
        onSubmit={(values, { setSubmitting }) => {
          const { profession, position, employer } = values;
          setUserData({ employer, position, profession }, hash, token);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <form
            id="test"
            className={classes.rootForm}
            onSubmit={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            {inputs.map(({ label, type, name, value }, index) => {
              return (
                <div key={`${label}-${index}`}>
                  {/* {name === "profession" ? (
                    <Divider className={classes.dividerBold} />
                  ) : null} */}
                  <div className={classes.inputBox}>
                    <Typography>{label}</Typography>
                    <OutlinedInput
                      margin="dense"
                      placeholder={errors[name]}
                      className={
                        !errors[name] ? classes.inputOk : classes.errorInput
                      }
                      type={type}
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[value]}
                    />
                  </div>
                </div>
              );
            })}

            <Button
              color="primary"
              variant="contained"
              className={classes.submitBtn}
              disabled={!isValid}
              onClick={() => setOpen(true)}
            >
              Запази и продължи по късно
            </Button>
            <Dialog open={open}>
              <DialogTitle>
                <Typography align="center">Запази промените?</Typography>
              </DialogTitle>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() => setOpen(false)}
                  color="primary"
                  variant="contained"
                >
                  отказ
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit();
                    setOpen(false);
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  запази
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
      <div className={classes.forms} >
        <div>
          {selectedUser.companyName && (
            beneficialOwnersPassed
              ? <>
                <Typography>Действителни собственици</Typography>

                <IconButton onClick={downloadBeneficialOwners}>
                  <IsPassedIcon bool={beneficialOwnersPassed} />
                  <PDFIcon fontSize="large" color="primary" />
                </IconButton>
              </>
              : <></>
          )}
        </div>
        <div>
          {pepPassed
            ? <>
              <Typography>Декларация за политическа личност</Typography>
              <IconButton onClick={downloadPepDeclaration}>
                <IsPassedIcon bool={pepPassed} />
                <PDFIcon fontSize="large" color="primary" />
              </IconButton>
            </>
            : <></>
          }

        </div>
        </div >

        <div style= {{display:"block"}}>
            {selectedUser.companyName
              ? <>
                <Typography>Има намерено ЕГН в Търговски регистър</Typography>
                <IconButton>
                  <IsPassedIcon bool={companyUserIdent} />
                </IconButton>
              </>
              : <></>
            }
        </div>
      </div >
  );
};

export default UserDetailsForm;
