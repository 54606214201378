import React, { useContext } from "react";
import MainContext from "../context/MainContext";

const RenderOnAgent = ({ children }) => {
  const context = useContext(MainContext);
  const roles = context.user.roles;
  const isAgent = !roles.includes("agent");

  return <div> {isAgent ? children : null}</div>;
};

export default RenderOnAgent;
