import { GET_COMMENTS } from '../../utils/constants';
import { HttpService } from '../../app/HttpService';

const getComments = async (token) => { // queries === object
    const bearer = localStorage.getItem('hash');

    return await HttpService.get(GET_COMMENTS(token), {
        headers: {
            'Authorization': bearer
        }
    }).then(resp => {
        return resp.data;
    }).catch(err => {
        console.error(err)
    })
}

export default getComments