import React, { useEffect, useState } from 'react'
import Loading from '../components/basic/LoadingTable';
import UserService from '../SecuredRoutes/UserService';
import MainContext from './MainContext'
import axios from "axios";

const ContextProvider = (props) => {

    const [user, setUser] = useState(null);
    const [selectedScreen, setSelectedScreen] = useState({});
    const [loading, setLoading] = useState(true)
    const currentUser = localStorage.getItem('user');
    const hash = localStorage.getItem('hash');
    const [isOpenIDCardModal, setIsOpenIDCardModal] = useState(false);
    const [consents, setConsents] = useState([]);
    const [selectedAgentID, setSelectedAgentID] = useState(Number());

    const selectAgentID = (ID) => {
        setSelectedAgentID(ID)
    }

    const addConsents = (data) => {
        setConsents(data);
    }

    const idCardModalOpen = () => {
        setIsOpenIDCardModal(val => !val)
    }

    const [agentUi, setAgentUi] = useState({
        "dateCreated": null,
        "homeDetails": null,
        "id": null,
        "instructionEnterOtherDetails": null,
        "instructionFinal": null,
        "instructionIdBack": null,
        "instructionIdFace": null,
        "instructionIdentified": null,
        "instructionMultiUsers": null,
        "instructionPep": null,
        "instructionSelfieVideo": null,
        "instructionSingleUser": null,
        "lblPep": null,
        "lblScanIdBack": null,
        "lblScanIdFace": null,
        "lblSelfie": null,
        "lblSelfieVideoCheckbox": null,
        "lng": null,
        "logoUrl": null,
        "pepUrl": null,
        "usePep": null
    });

 

    const updateAgentUi = (newUi) => {
        setAgentUi(val => Object.assign(val, newUi))
    }

    // modals
    const [companyModal, setCompanyModal] = useState({
        isOpen: false,
        companyToken: ""
    })

    const openCompanyModal = (isOpen, companyToken) => {
        setCompanyModal({
            isOpen,
            companyToken
        })
    }

    // const token = "asdasdkajshdkjahsdkjahsfkjashdkajshdkajhsdkjahsdkjasd" // will be keycloak jwt
    const userName = UserService.getUsername()
    const userData = UserService.getParsedToken();
    const tokenKC = UserService.getToken();

    useEffect(() => {

        axios.interceptors.response.use(// on 401 logging out
            response => {
                if (!UserService.getToken()) {
                    UserService.updateToken((x) => console.log(x))
                }
                return response
            }
            ,
            error => {
                if (error.response.status === 401) {
                    window.location.href = '/';
                    UserService.doLogout()
                    setUser(null);
                    setLoading(false);
                }
                if (error.response.status === 400) {
                  return error.response;
                }
            });

        // check session
        if (userName && tokenKC) {
            localStorage.setItem("user", userName)
            localStorage.setItem("hash", `Bearer ${tokenKC}`)
            // console.log(UserService.getToken())
            setUser({ name: userName, hash: `Bearer ${tokenKC}`, roles: userData?.roles, jwt: UserService.getToken() });
            setLoading(false);
        } else {
            setUser(null);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [])

    const selectScreen = (screen) => {
        if (JSON.stringify(screen) === JSON.stringify(selectedScreen)) {
            setSelectedScreen({});
        } else {
            setSelectedScreen(screen);
        }
    }

    const signIn = (user) => {
        localStorage.setItem('user', user.name);
        localStorage.setItem('hash', `Bearer ${tokenKC}`);
        setUser({ name: currentUser, hash })
    }

    const signOut = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('hash');
        setUser(null)
    }

    if (loading) {
        return <div>
            <Loading />
        </div>
    }

    return (
        <MainContext.Provider
            value={{
                selectedScreen,
                user,
                companyModal,
                agentUi,
                isOpenIDCardModal,
                consents,
                selectedAgentID,
                selectAgentID,
                addConsents,
                idCardModalOpen,
                updateAgentUi,
                openCompanyModal,
                selectScreen,
                signIn,
                signOut,
            }}
        >
            <div>
                {props.children}
            </div>
        </MainContext.Provider>
    )
}

export default ContextProvider
