import { createContext } from 'react';

const MainContext = createContext({
    selectedScreen: null,
    user: null,
    dates: [],
    companyModal: { isOpen: false, companyToken: "" },
    agentUi: null,
    selectedUser: null,
    consents: [],
    selectedAgentID: Number(),
    selectAgentID: () => { },
    addConsents: () => { },
    updateSelectedUser: () => { },
    updateAgentUi: () => { },
    openCompanyModal: () => { },
    selectScreen: () => { },
    signIn: () => { },
    signOut: () => { }
});

export default MainContext;