import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import BackupIcon from "@material-ui/icons/Backup";
import { useContext } from "react";
import MainContext from "../../context/MainContext";
import UploadCertificate from "./UploadCertificate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  certBtn: {
    width: "100%",
  },
}));

const Screens = () => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const selectedScreen = context.selectedScreen;

  const handleSelect = (id) => () => {
    context.selectScreen(id);
  };

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Опции
          </ListSubheader>
        }
        className={classes.root}
      >
        <UploadCertificate />

        <ListItem
          button
          selected={selectedScreen.logo}
          onClick={handleSelect({ id: 0, logo: true })}
        >
          <ListItemIcon>
            <BackupIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Лого" />
        </ListItem>
        <ListItem
          button
          selected={selectedScreen.consents}
          onClick={handleSelect({ id: 0, consents: true })}
        >
          <ListItemIcon>
            <VerifiedUserIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Съгласия" />
        </ListItem>
        <ListItem
          button
          selected={selectedScreen.screens}
          onClick={handleSelect({ id: 0, screens: true })}
        >
          <ListItemIcon>
            <QueuePlayNextIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Екрани" />
        </ListItem>
      </List>
    </>
  );
};

export default Screens;
