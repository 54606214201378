import { createSlice } from "@reduxjs/toolkit";

export const MAIN_LAYOUT_SLICE = "main-layout-slice"

const initialState = {
    loading: false,

}

const mainLayoutSlice = createSlice({
    name: MAIN_LAYOUT_SLICE,
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },

    }
})

export const selectLoading = (state) => state[MAIN_LAYOUT_SLICE].loading;

export const {
    setLoading
} = mainLayoutSlice.actions

export default mainLayoutSlice.reducer;
