import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { IconButton, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setOpenVideo, setPhotosModal, setVideoFiles, setVideoLink } from "./userDetailsSlice";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "6vh",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  imageList: {
    width: 600,
    height: 200,
  },
  redIcon: {
    color: "red",
  },
  whiteIcon: {
    color: "white",
  },
  videoBox: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    cursor: "pointer",
  },
  playIcon: {
    fontSize: 90,
    color: "#FFF",
  },
  noVideo: {
    fontSize: 90,
    color: "#000",
  },
  videoPreview: {
    cursor: "pointer",
    position: "unset",
  },
}));

const IdCard = ({
  idFrontImages,
  idBackImages,
  documentPicture,
  smilingFaceImages,
  frowningFaceImages,
  video,
  videoFiles
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleIdFrontImageClick = () => {
    dispatch(setPhotosModal({ photosType: "frontImages", isOpen: true })); // important !
  };

  const handleBackImageClick = () => {
    dispatch(setPhotosModal({ photosType: "backImages", isOpen: true })); // important !
  };

  const handleMoIImageClick = () => {
    dispatch(setPhotosModal({ photosType: "MoIImage", isOpen: true })); // important !
  };

  const handleFrowningImageClick = () => {
    dispatch(
      setPhotosModal({ photosType: "frowningFaceImages", isOpen: true }) // important !
    );
  };

  const handleSmilingImageClick = () => {
    dispatch(setPhotosModal({ photosType: "smilingFaceImages", isOpen: true })); // important !
  };

  const openVideo = () => {
    dispatch(setOpenVideo(true));
    dispatch(setVideoLink(video));
    dispatch(setVideoFiles(videoFiles))
  };

  const [videoError, setVideoError] = React.useState(false);

  let mainFrontIdImageSrc = "";
  let mainBackIdImageSrc = "";
  let mainSmilingFaceSrc = "";
  let mainFrowningFaceSrc = "";

  if (idFrontImages.length) {
    mainFrontIdImageSrc = idFrontImages.find((img) => {
      return img["main"] === true;
    });
  }

  if (idBackImages.length) {
    mainBackIdImageSrc = idBackImages.find((img) => {
      return img["main"] === true;
    });
  }

  if (smilingFaceImages.length) {
    mainSmilingFaceSrc = smilingFaceImages[0].filename;
  } else {
    mainSmilingFaceSrc = "/images/image_default.png";
  }

  if (frowningFaceImages.length) {
    mainFrowningFaceSrc = frowningFaceImages[0].filename;
  } else {
    mainFrowningFaceSrc = "/images/image_default.png";
  }

  return (
    <>
      <div className={classes.root}>
        <ImageList rowHeight={180} className={classes.imageList} cols={3}>
          <ImageListItem>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/image_default.png";
              }}
              className={classes.image}
              onClick={handleIdFrontImageClick}
              src={
                mainFrontIdImageSrc
                  ? mainFrontIdImageSrc.filename
                  : "/images/image_default.png"
              }
              alt="Лична карта лице"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/image_default.png";
              }}
              className={classes.image}
              src={
                mainBackIdImageSrc
                  ? mainBackIdImageSrc.filename
                  : "/images/image_default.png"
              }
              alt="Лична карта гръб"
              onClick={handleBackImageClick}
            />
          </ImageListItem>
          <ImageListItem>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/image_default.png";
              }}
              className={classes.image}
              src={documentPicture || "/images/image_default.png"}
              alt="Снимка от МВР"
              onClick={handleMoIImageClick}
            />
          </ImageListItem>
        </ImageList>

        <ImageList rowHeight={180} className={classes.imageList} cols={3}>
          <ImageListItem>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/image_default.png";
              }}
              className={classes.image}
              src={mainSmilingFaceSrc}
              alt="Усмихнато изражение"
              onClick={handleSmilingImageClick}
            />
          </ImageListItem>
          <ImageListItem>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/image_default.png";
              }}
              className={classes.image}
              src={mainFrowningFaceSrc}
              alt="Неутрално изражение"
              onClick={handleFrowningImageClick}
            />
          </ImageListItem>
          <ImageListItem className={classes.videoBox}>
            {video && !videoError ? (
              <>
                <IconButton onClick={openVideo}>
                  <div
                    style={{
                      position: "absolute",
                      width: 200,
                      height: 170,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlayIcon className={classes.playIcon} />
                  </div>
                  <video
                    onError={() => setVideoError(true)}
                    className={classes.videoPreview}
                    height={170}
                    width={200}
                    src={video}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <PlayIcon className={classes.noVideo} />
                <Typography>Няма запис</Typography>
              </>
            )}
          </ImageListItem>
        </ImageList>
      </div>
    </>
  );
};

export default IdCard;
