import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Screens from "../components/settings/Screens";
import MainContext from "../context/MainContext";
import { SCREENS } from "../utils/constants";
import Button from "@material-ui/core/Button";
import ScreenMessage from "../components/settings/ScreenMessage";
import getAgentUi from "../services/GET/getAgentUi";
import updateAgentUi from "../services/PUT/updateAgentUi";
import UploadLogo from "../components/settings/UploadLogo";
import UpdateConsents from "../components/settings/UpdateConsents";
import { useHistory } from "react-router";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    marginTop: "3%",
  },
  container: {
    displayL: "flex",
    flexGrow: "1",
    justifyContent: "space-between",
  },
  component: {
    width: "100%",
    margin: "auto",
  },
  saveBtn: {
    float: "right",
    margin: "5% 10%",
  },
  noSelectedMsg: {
    fontSize: "larger",
    opacity: 0.8,
  },
  messageTitle: {
    marginTop: "50%",
  },
});

const Settings = () => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const history = useHistory();
  const hash = context.user.hash;

  const { selectedScreen } = context;

  useEffect(() => {
    if (!context.selectedAgentID) history.goBack();
    else {
      context.selectScreen({});
      getUi();
    }
    // eslint-disable-next-line
  }, []);

  const getUi = async () => {
    const agentID = context.selectedAgentID;
    const messages = await getAgentUi(agentID, hash);
    context.updateAgentUi(messages.ui);
    context.addConsents(messages.consent);
    // add consents to context here
  };

  const saveChanges = () => {
    const agentID = context.selectedAgentID;

    updateAgentUi(context.agentUi, agentID, hash, []).then((resp) => {
      console.log("updated");
    });
  };
  return (
    <div>
      <Typography className={classes.title} variant="h4">
        Настройки
      </Typography>
      <Box className={classes.container} boxShadow={2} p={1}>
        <Grid container>
          <Grid item xs={5}>
            <Screens />
          </Grid>
          <Grid item xs={7} style={{ margin: "auto" }}>
            <div style={{ textAlign: "center" }}>
              <>
                {selectedScreen && selectedScreen.logo && (
                  <UploadLogo getUi={getUi} />
                )}

                {selectedScreen && selectedScreen.consents && (
                  <UpdateConsents getUi={getUi} />
                )}

                {/* START ACCORDION SCREENS */}

                {selectedScreen &&
                  selectedScreen.screens &&
                  SCREENS.map(({ id, title, key }) => {
                    return (
                      <Accordion
                        key={id}
                        TransitionProps={{ unmountOnExit: true }}
                        defaultExpanded={id === 1 && true}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexGrow: "1" }}>
                          <ScreenMessage screenId={id} screenKey={key} />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}

                {/* END ACCORDION SCREENS */}

                {(selectedScreen.screens ||
                  selectedScreen.logo ||
                  selectedScreen.consents) && (
                  <Button
                    onClick={saveChanges}
                    size="large"
                    className={classes.saveBtn}
                    variant="contained"
                    color="primary"
                  >
                    Запази
                  </Button>
                )}

                {!selectedScreen.screens &&
                  !selectedScreen.logo &&
                  !selectedScreen.consents && (
                    <Typography className={classes.noSelectedMsg}>
                      Изберете конпонент за персонализиране
                    </Typography>
                  )}
              </>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Settings;
