import axios from 'axios/index';
import { SET_RISK } from '../../utils/constants';

const setRisk = async (risk, hash, token) => {
    return await axios.put(SET_RISK(token, risk),
        {},
        {
            headers: {
                "Accept": "application/json",
                "Accept-language": "bg",
                "Authorization": hash,
            }
        }).then(resp => {
            // return resp.data;
        }).catch(err => {
            console.error(err)
            alert(err)
        })
}

export default setRisk;