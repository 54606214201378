import axios from 'axios/index';
import { GET_COMPANY_BY_TOKEN } from '../../utils/constants';

const getCompanyByToken = async (token, hash) => { // queries === object

    return await axios.get(GET_COMPANY_BY_TOKEN(token), {

        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        }

    }).then(resp => {
        return resp.data
    }).catch(err => {
        console.error(err)
    })

}

export default getCompanyByToken;