import storage from "redux-persist/lib/storage";
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from "redux-persist";
import tabsReducer, { SELECTED_TABLE_TAB } from '../components/main/tabsSlice';
import userDetailsReducer, { USER_DETAILS } from "../components/userDetails/userDetailsSlice";
import mainLayoutReducer, { MAIN_LAYOUT_SLICE } from "../components/layouts/LayoutSlice";
import tableReducer, { TABLE_SLICE } from "../components/main/tableSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whiteList: [],
  blacklist: [USER_DETAILS, MAIN_LAYOUT_SLICE, TABLE_SLICE]
};

const rootReducer = combineReducers({
  [SELECTED_TABLE_TAB]: tabsReducer,
  [USER_DETAILS]: userDetailsReducer,
  [MAIN_LAYOUT_SLICE]: mainLayoutReducer,
  [TABLE_SLICE]: tableReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
