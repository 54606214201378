import Container from "@material-ui/core/Container";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import UserService from "../SecuredRoutes/UserService";

const useStyles = makeStyles({
  formBox: {
    width: "20%",
    minWidth: "400px",
    marginTop: "10vh",
    textAlign: "center",
    padding: "50px 20px 60px 20px",
    boxShadow: "2px 1px 4px 2px lightGray",
  },
  field: {
    width: "100%",
    textAlign: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  submitBtn: {
    width: "80%",
  },
  innerBox: {
    width: "100%",
  },
});

const Login = () => {
  const classes = useStyles();
  console.log("3/22/2022");
  return (
    <Container className={classes.formBox}>
      <Typography variant="subtitle1">
        Вие сте влезли в акаунта си <b>{UserService.getUsername()}</b>
        <Button
          onClick={() => UserService.doLogout()}
          variant="contained"
          color="primary"
        >
          Изход
        </Button>
      </Typography>
      <div>
        <br />
        <Link style={{ textDecoration: "none" }} to="/home">
          {" "}
          <Button variant="contained" color="primary">
            Към началната страница
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Login;
