import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import MainContext from "../../context/MainContext";
import { addIdentification } from "../../services/POST/addIdentification";
import getAgents from "../../services/GET/getAgents";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  offlineButton: {
    color: "#FFF",
    marginLeft: 20,
  },
  label: {},
  filed: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const OfflineKYCModal = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const context = useContext(MainContext);
  const hash = context.user.hash;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [agentOptions, setAgentOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [finalMsg, setFinalMsg] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  async function handleSubmit1(values) {
    setLoading(true);
    const resp = await addIdentification({
      agentId: values?.agentId,
      personalNumber: values.egn,
      documentNumber: values.idNumber,
      uic: values.eik,
      hash: hash,
    });

    setLoading(false);

    if (resp.status === 400 || resp.status !== 200) {
      setFinalMsg(resp.data.message);
      // if (resp.data.message === "documentNumberNotFound") {
      //   setFinalMsg("Невалиден номер на документ");
      // }else{
      //   setFinalMsg("");
      // }
    } else {
      setTimeout(() => {
        handleClose();
      }, 3000);
      reset();
      setFinalMsg("Създаден успешно!");
    }

    // console.log("====================================");
    // console.log(resp);
    // console.log("====================================");
  }

  useEffect(() => {
    fetchAgents();
    // eslint-disable-next-line
  }, []);

  async function fetchAgents() {
    const resp = await getAgents(hash);
    setAgentOptions(resp);
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} className={classes.offlineButton}>
        Offline KYC
      </Button>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography align="center"> Offline KYC</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          <form onSubmit={handleSubmit(handleSubmit1)}>
            <Grid container direction="column" spacing={3}>
              <Grid className={classes.filed} item>
                <Typography htmlFor="egn">ЕГН*</Typography>
                <TextField
                  error={!!errors.egn}
                  style={{ width: 260 }}
                  margin="dense"
                  variant="outlined"
                  id="egn"
                  name="egn"
                  type="number"
                  {...register("egn", { required: true, minLength: 10 })}
                />
              </Grid>
              <Grid className={classes.filed} item>
                <Typography htmlFor="egn">No на ЛК*</Typography>
                <TextField
                  error={!!errors.idNumber}
                  style={{ width: 260 }}
                  variant="outlined"
                  margin="dense"
                  id="idNumber"
                  name="idNumber"
                  type="number"
                  {...register("idNumber", { required: true })}
                />
              </Grid>
              <Grid className={classes.filed} item>
                <Typography htmlFor="egn">ЕИК</Typography>
                <TextField
                  style={{ width: 260 }}
                  margin="dense"
                  variant="outlined"
                  id="eik"
                  name="eik"
                  type="number"
                  {...register("eik", { required: false })}
                />
              </Grid>
              <Grid className={classes.filed} item>
                <Typography htmlFor="egn">Агент</Typography>
                <Select
                  style={{ width: 260 }}
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  name="agentId"
                  input={
                    <OutlinedInput error={!!errors.agentId} margin="dense" />
                  }
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}
                  onOpen={() => setMenuOpen(true)}
                  {...register("agentId", { required: false })}
                >
                  {agentOptions.map((agent,i) => {
                    return <MenuItem key={i} value={agent.id}>{agent.name}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Grid>
            <Typography style={{ height: 26 }} color="error">
              {errors.egn && (
                <span>
                  Липсва ЕГН или е с невалидна дължина
                  <br />
                </span>
              )}
              {errors.agentId && (
                <span>
                  Моля изберете Агент
                  <br />
                </span>
              )}

              {errors.idNumber && <span>Липсва номер на ЛК</span>}
            </Typography>
            <div style={{ marginTop: 10 }}>
              {loading ? (
                <CircularProgress
                  style={{ display: "block", marginLeft: "auto" }}
                  color="primary"
                  size={26}
                />
              ) : (
                <Button
                  style={{ display: "block", marginLeft: "auto" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
          <Typography align="center">{finalMsg}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OfflineKYCModal;
