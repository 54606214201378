import React, { useEffect, useState } from "react";
import getCompanyOwners, { downloadOwnerPep } from "../../services/GET/getCompanyOwners";
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Paper } from "@material-ui/core";
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDownwardRounded } from "@material-ui/icons";
import { blueGrey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: blueGrey[400],
    color: "#ffffff",
  },
  summary: {
    margin: "auto",
    textTransform: "uppercase",
  },
  icon: {
    color: "#ffffff",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "medium",
  },
  paperClickable: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "medium",
    cursor: "pointer",
    minHeight: 27,
    width: 350,
    margin: "auto",
    "&:hover": {
      background: "lightGray",
    },
  },
  clickable: {
    cursor: "pointer",
    color: "Blue",
    "&:hover": {
      // background: "lightGray",
      opacity: 0.7
    },
  }
}));

const CompanyOwners = ({ hash, companyToken }) => {
  const classes = useStyles();
  const [owners, setOwners] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getOwners();
    // eslint-disable-next-line
  }, []);

  const getOwners = async () => {
    const owners = await getCompanyOwners(hash, companyToken);
    console.log(owners)
    setOwners(owners);
  };

  const handleDownload = async (index, name) => {
    document.body.style.cursor = 'wait';
    try {
      const data = await downloadOwnerPep(hash, companyToken, index)

      document.body.style.cursor = 'default'

      const href = URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${name.replace(" ", "_")}.pdf`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      document.body.style.cursor = 'default'
      alert("Нещо се обърка");

    } finally {
      document.body.style.cursor = 'default'
    }
    document.body.style.cursor = 'default'
  }

  return (
    <>
      {owners.length
        ? <>
          <Paper onClick={() => setOpen(true)} className={[classes.paperClickable, classes.paperClickable]}>
            <Typography><b>Декларации действителни собственици</b></Typography>
          </Paper>

          <Dialog open={open} onClose={() => setOpen(false)}>

            <DialogTitle>Действителни собственици</DialogTitle>

            <DialogContent>
              <Grid container direction="column" >
                {owners.length > 0 && owners?.map((_, i) => {
                  return <Grid key={i} item onClick={() => handleDownload(_.index, _.name)}>
                    <Typography align="center" className={classes.clickable}>
                      {_.name}
                    </Typography>
                  </Grid>
                })}

                {!owners.length && <Typography align="center" className={classes.clickable}>
                  Няма записи
                </Typography>}
              </Grid>
            </DialogContent>
          </Dialog >
        </>
        : <></>
      }

    </>

  );
  // eslint-disable-next-line
  return (
    <div>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ArrowDownwardRounded className={classes.icon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.summary}>собственици</Typography>
        </AccordionSummary>

        {owners &&
          owners.map((owner) => {
            return (
              <AccordionDetails>
                <Typography>{owner}</Typography>
              </AccordionDetails>
            );
          })}

        {!owners.length && <Typography>Няма записи</Typography>}
      </Accordion>
    </div>
  );
};

export default CompanyOwners;
