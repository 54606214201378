import { Box, Button, CircularProgress, Grid, OutlinedInput, Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import DataTable from '../components/regix/DataTable';
import MainContext from '../context/MainContext';
import regixCheck from '../services/POST/regixCheck';

const Regix = () => {
    const context = useContext(MainContext);
    const hash = context.user.hash;
    // eslint-disable-next-line
    const [egn, setEgn] = useState("");
    const [error, setError] = useState(false);
    const [result, setResult] = useState("");

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [data, setData] = useState([])

    // agentName: 'John Doe',
    // employeeIdentifier: '123456',
    // employeeNames: 'John Smith',
    // requestDate: '2023-06-08',
    // remark: 'Lorem ipsum dolor sit amet',
    // responsiblePersonIdentifier: '987654',
    // responsiblePersonNames: 'Jane Doe',
    // checkType: 'REGULAR_VALIDITY_CHECK',
    // status: 'VALID',

    const handleRegix = async () => {
        if (egn.length < 10) {
            setError(true)
            return
        }
        try {
            setLoading(true)
            const resp = await regixCheck(hash, egn)
            setData(resp.data)
            if (JSON.stringify(resp.data) === "[]") setResult("Не е открит запис за проверка на това ЕГН")
        } catch (error) {
            console.log(error);
            setResult("Не е открит запис за проверка на това ЕГН")
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <Typography style={{ marginTop: 30, marginBottom: 10 }} align='center'>Въведете ЕГН за справка кога и от кой е било проверявано в Реджикс.</Typography>
            <Box display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="10vh"
                minWidth={"80vw"}
            >
                <Grid container alignItems="center" justifyContent='center' spacing={3} >
                    <Grid item>
                        <Typography>ЕГН: </Typography>
                    </Grid>
                    <Grid item>
                        <OutlinedInput
                            onChange={({ target: { value } }) => setEgn(value)}
                            onInput={() => setError(false)}
                            margin="dense"
                            multiline={false}
                            error={error}
                        />
                    </Grid>
                    <Grid item>
                        <Button onClick={handleRegix} disabled={loading} color="primary" variant="contained">
                            Провери
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <div style={{ display: "flex", justifyContent: "center", height: 50 }}>
                {loading ? <CircularProgress size={25} /> : null}
            </div>
            {error ? <Typography align="center" color="error">Невалидно ЕГН</Typography> : null}

            {JSON.stringify(data) !== "[]"
                ? data.map((x, i) => {
                    return <div key={i}>
                        <Typography style={{ padding: 20 }} align="center">Проверка {i + 1}</Typography>
                        < DataTable data={x} />
                    </div>

                })
                : <Typography style={{ paddingTop: 20 }} align="center">{result}</Typography>
            }
            <div style={{ marginBottom: 50 }}></div>
        </>

    )
}

export default Regix