import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns/esm';

const useStyles = makeStyles({
    box: {
        minWidth: 650,
        maxWidth: 700,
        margin: "auto"
    },
    table: {
        minWidth: 700,
    },
});

const DataTable = ({ data }) => {
    const classes = useStyles();


    const CHECK_TYPE = {
        REGULAR_VALIDITY_CHECK: "Стандартна ",
        MANUAL_CHECK: "Ръчна ",
        AUTO_CHECK: "Автоматична ",
    }

    const STATUSES = {
        VALID: "Успешна проверка",
        INVALID: "Неуспешна проверка"
    }

    const FORMAT_DATE = (d) => format(new Date(d), "MM.dd.yyyy HH:mm")

    return (
        <TableContainer className={classes.box} component={Paper}>
            <Table size="small" className={classes.table} aria-label="key value table">
                <TableBody >
                    <TableRow>
                        <TableCell component="th" scope="row">Време на проверка</TableCell>
                        <TableCell align='right'>{FORMAT_DATE(data?.requestDate || 0)}</TableCell>
                    </TableRow>

                    {/* <TableRow>
                        <TableCell component="th" scope="row">Лице</TableCell>
                        <TableCell align='right'>{ }</TableCell>
                    </TableRow> */}

                    <TableRow>
                        <TableCell component="th" scope="row">Име на агент</TableCell>
                        <TableCell align='right'>{data?.agentName}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Идентификатор на агент</TableCell>
                        <TableCell align='right'>{data?.responsiblePersonIdentifier}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Лице за контакт на агент</TableCell>
                        <TableCell align='right'>{data?.responsiblePersonNames}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Тип на проверката</TableCell>
                        <TableCell align='right'>{CHECK_TYPE[data?.checkType]}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Служител</TableCell>
                        <TableCell align='right'>{data?.employeeNames}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Идентификатор на служител</TableCell>
                        <TableCell align='right'>{data?.employeeIdentifier}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Резултат</TableCell>
                        <TableCell align='right'>{STATUSES[data?.status]}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">Ремарк</TableCell>
                        <TableCell align='right'>{data?.remark}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;