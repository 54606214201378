import axios from 'axios/index';
import { UPDATE_AGENT_UI } from '../../utils/constants';

const updateAgentUi = async (ui, agentId, hash, consents) => { // queries === object
    const data = {
        "consent": consents,
        "ui": ui
    }
    return await axios.put(UPDATE_AGENT_UI(agentId),
        data, {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        },

    }).then(resp => {
        return resp.status;
    }).catch(err => {
        console.error(err)
    })

}

export default updateAgentUi