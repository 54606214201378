import axios from 'axios/index';
import { GET_USER_BY_TOKEN } from '../../utils/constants';

const getUserByToken = async (token, hash) => { // queries === object

    return await axios.get(GET_USER_BY_TOKEN(token), {

        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
            "Authorization": hash,
        }

    }).then(resp => {
        return resp.data
    }).catch(err => {
        console.error(err)
    })
}

export default getUserByToken;