import React from "react";
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDownwardRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  summary: {
    margin: "auto",
    textTransform: "uppercase",
  },
}));

const CompanyHistory = () => {
  const classes = useStyles();

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardRounded />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.summary}>история</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>history here</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CompanyHistory;
