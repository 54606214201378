import React, { useContext } from "react";
import MainContext from "../context/MainContext";

const RenderOnModerator = ({ children }) => {
  const context = useContext(MainContext);
  const roles = context?.user.roles;
  const isModerator = roles.includes("moderator");

  return <div> {isModerator ? children : null}</div>;
};

export default RenderOnModerator;
