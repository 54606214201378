import React, { useContext } from "react";
import MainContext from "../../context/MainContext";
import AddConsent from "./AddConsent";
import Consent from "./Consent";
// import { Document } from "react-pdf";
// import ReactPDF from "@react-pdf/renderer";

const UpdateConsents = ({ getUi }) => {
  const context = useContext(MainContext);
  const { consents } = context;

  return (
    <div>
      <AddConsent getUi={getUi} />
      {consents.map((data, index) => {
        return (
          <Consent
            getUi={getUi}
            data={data}
            index={index}
            key={`${data.label}--${index}`}
          />
        );
      })}
    </div>
  );
};

export default UpdateConsents;
