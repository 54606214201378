import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
const userHash = localStorage.getItem('hash');

const withBearer = userHash?.includes("Bearer") ? userHash : `Bearer ${userHash}`

export const HttpService = axios.create({
    baseURL: API_BASE_URL,
    timeout: 1000,
    headers: { 'Authorization': withBearer }
});