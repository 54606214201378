import { createSlice } from '@reduxjs/toolkit';

export const SELECTED_TABLE_TAB = 'table-tab';

const initialState = {
    openTab: 0,
}

const tabsSlice = createSlice({
    name: SELECTED_TABLE_TAB,
    initialState,
    reducers: {
        setTab(state, { payload }) {
            state.openTab = payload
        }
    }
})

export const selectTab = (state) => state[SELECTED_TABLE_TAB].openTab;

export const { setTab } = tabsSlice.actions;

export default tabsSlice.reducer;