import { Button } from "@material-ui/core";
import { Dropzone, FileItem, FullScreenPreview } from "dropzone-ui";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContext from "../../context/MainContext";
import getUserByToken from "../../services/GET/getUserByToken";
import { uploadImage } from "../../services/POST/uploadImage";
import { IMAGE_TYPE } from "../../utils/constants";
import { getBase64 } from "../../utils/helperFunctions";
import { selectPhotosModal, setCurrentUser } from "./userDetailsSlice";

const UploadPhoto = () => {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState([]);
  const [imageSrc, setImageSrc] = React.useState(undefined);
  const [base64, setBase64] = React.useState(null);
  const context = useContext(MainContext);
  const hash = context.user.hash;

  const identityToken = window.location.hash.split("/").pop();
  const isFront = useSelector(selectPhotosModal).photosType === "frontImages";

  const updateFiles = (incomingFiles) => {
    setFile(incomingFiles);
    getBase64(incomingFiles[0].file, (x) => setBase64(x));
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const onDelete = () => {
    setFile([]);
  };

  const handleUpload = async () => {
    try {
      await uploadImage({
        base64img: base64,
        identityToken,
        hash,
        type: isFront ? IMAGE_TYPE.FRONT : IMAGE_TYPE.BACK,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        updateCurrentUser();
      }, 800);
    }

    setFile([]);
  };

  async function updateCurrentUser() {
    const user = await getUserByToken(identityToken, hash);
    dispatch(setCurrentUser({ ...user }));
  }

  return (
    <div>
      <Dropzone
        value={file}
        onChange={updateFiles} // this CB will directly upload and refresh the list of photos
        onDrop={updateFiles}
        maxFiles={1}
        header={false}
        minHeight="200px"
        label="Качи Снимка"
        accept="image/*"
        maxFileSize={5998000}
      >
        <FileItem
          {...file[0]}
          onDelete={onDelete}
          onSee={handleSee}
          preview
          info
          hd
        />
        <FullScreenPreview
          imgSource={imageSrc}
          openImage={imageSrc}
          onClose={(e) => handleSee(undefined)}
        />
      </Dropzone>
      {file.length !== 0 && (
        <Button onClick={handleUpload} color="primary" variant="contained">
          upload
        </Button>
      )}
    </div>
  );
};

export default UploadPhoto;
