import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import deleteIDCard from "../../services/DELETE/deleteIDCard";
import { ID_CARD_MODAL_TITLES } from "../../utils/constants";
import setMainIDCard from "../../services/PUT/setMainIDCard";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectPhotosModal,
  setPhotosModal,
} from "./userDetailsSlice";
import UploadPhoto from "./UploadPhoto";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  imageList: {
    width: 600,
    height: 200,
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
      "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
  },
  redIcon: {
    color: "red",
    background: "gray",
    "&:hover": {
      color: "white",
      background: "red",
    },
  },
  whiteIcon: {
    color: "white",
    background: "gray",
    "&:hover": {
      background: "green",
    },
  },
  actionIcon: {
    opacity: 0.7,
    padding: 10,
    margin: 5,
    "&:hover": {
      opacity: 1,
    },
  },
  mainTrue: {
    background: "green",
  },
  closeDialogIcon: {
    position: "absolute",
    right: 5,
    top: 5,
    color: "red",
    background: "white",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  addPhoto: {
    maxHeight: 300,
    maxWidth: 300,
  },
}));

const IdCardModal = ({ getUser }) => {
  const classes = useStyles();
  const hash = localStorage.getItem("hash");
  const [selectedSideImageSet, setCardImages] = useState([]);
  const [title, setTitle] = useState("");
  const [dialogSize, setDialogSize] = useState("lg");
  const [imageRows, setImageRows] = useState(3);
  const dispatch = useDispatch();

  const selectedUser = useSelector(selectCurrentUser);
  const { photosType, isOpen } = useSelector(selectPhotosModal);

  const MoIImage = selectedUser.documentPicture;

  useEffect(() => {
    if (selectedSideImageSet && selectedSideImageSet.length === 1) {
      setImageRows(1);
      setDialogSize("sm");
    }

    if (selectedSideImageSet && selectedSideImageSet.length === 2) {
      setImageRows(2);
      setDialogSize("md");
    }

    if (selectedSideImageSet && selectedSideImageSet.length >= 3) {
      setImageRows(3);
      setDialogSize("lg");
    }
  }, [selectedSideImageSet]);

  useEffect(() => {
    if (selectedUser && isOpen) {
      setTitle(ID_CARD_MODAL_TITLES[photosType]);
      setCardImages(selectedUser[photosType]);
    }
    // eslint-disable-next-line
  }, [selectedUser, photosType]);

  const handleClose = () => {
    dispatch(setPhotosModal({ photosType: "", isOpen: false }));
  };

  const handleUpdate = (id, main) => async () => {
    try {
      const changedMain = !main;
      const { status } = await setMainIDCard(hash, id, changedMain);
      if (status === 200) {
        getUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => async () => {
    try {
      const { status } = await deleteIDCard(hash, id);
      if (status === 200) {
        getUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const StyledDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={[classes.closeDialogIcon, classes.actionIcon].join(" ")}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={dialogSize}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <StyledDialogTitle onClose={handleClose}>{title}</StyledDialogTitle>
        <DialogContent>
          {ID_CARD_MODAL_TITLES[photosType] === "МВР снимка" ? (
            <ImageList
              rowHeight="auto"
              cols={3}
              style={{ justifyContent: "center" }}
            >
              <ImageListItem>
                <img
                  className={classes.image}
                  src={MoIImage}
                  alt={MoIImage}
                ></img>
              </ImageListItem>
            </ImageList>
          ) : (
            <ImageList rowHeight="auto" cols={imageRows}>
              {selectedSideImageSet?.map(({ filename, id, main }) => {
                return (
                  <ImageListItem key={id}>
                    <img
                      className={classes.image}
                      src={filename}
                      alt="Неутрално изражение"
                    />
                    {selectedSideImageSet.length !== 1 && (
                      <>
                        <ImageListItemBar
                          position="top"
                          actionIcon={
                            <IconButton
                              onClick={handleDelete(id)}
                              title={"Изтрии"}
                              className={[
                                classes.redIcon,
                                classes.actionIcon,
                              ].join(" ")}
                            >
                              <CloseIcon />
                            </IconButton>
                          }
                          actionPosition="right"
                          className={classes.titleBar}
                        />

                        <ImageListItemBar
                          position="bottom"
                          actionIcon={
                            <IconButton
                              onClick={handleUpdate(id, main)}
                              title={"Направи основна"}
                              className={[
                                classes.whiteIcon,
                                classes.actionIcon,
                                main && classes.mainTrue,
                              ].join(" ")}
                            >
                              <CheckIcon />
                            </IconButton>
                          }
                          actionPosition="left"
                          className={classes.titleBar}
                        />
                      </>
                    )}
                  </ImageListItem>
                );
              })}
              <ImageListItem key={34}>
                {(photosType === "frontImages" ||
                  photosType === "backImages") && <UploadPhoto />}
              </ImageListItem>
            </ImageList>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IdCardModal;
