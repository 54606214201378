import { TextField, Box, Grid, Typography, Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MainContext from "../../context/MainContext";
import { CONSENT_TEMPLATE } from "../../utils/constants";
import { makeStyles } from "@material-ui/core";
import updateAgentUi from "../../services/PUT/updateAgentUi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    textAlign: "left",
    padding: 20,
    marginTop: 20,
  },
}));

const AddConsent = ({ getUi }) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const { agentUi, selectedAgentID, consents } = context;
  const hash = context.user.hash;
  const [label, setLabel] = useState("");
  const [language, setLanguage] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);

  const currentConsent = { ...CONSENT_TEMPLATE };
  currentConsent.agent.id = selectedAgentID;

  useEffect(() => {
    if (label.length && language.length) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [label, language]);

  const handleSubmit = async () => {
    const newConsent = [{ label, lng: language }];
    const status = await updateAgentUi(
      agentUi,
      selectedAgentID,
      hash,
      newConsent
    );
    if (status === 200) {
      getUi(); // update screen with ne consent
    }
  };

  if (consents.length >= 4) {
    return (
      <Box boxShadow={4} className={classes.root}>
        <Typography color="error" align="center">
          Достигнахте максималния брой Съгласия
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Box boxShadow={4} className={classes.root}>
        <Typography align="center">Добави Съгласие</Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={9}>
            <TextField
              key={0}
              fullWidth
              variant="outlined"
              label="Лейбъл"
              size="small"
              onChange={({ target }) => setLabel(target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              key={1}
              fullWidth
              variant="outlined"
              label="Език"
              size="small"
              onChange={({ target }) => setLanguage(target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            {/*  */}
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={canSubmit}
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
            >
              Създай
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AddConsent;
