import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import BackupIcon from "@material-ui/icons/Backup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Dropzone, FileItem } from "dropzone-ui";
import MainContext from "../../context/MainContext";
import uploadCertificate from "../../services/POST/uploadCertificate";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  certBtn: {
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

const UploadCertificate = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const context = useContext(MainContext);
  const hash = context.user.hash;
  const agentId = context.agentUi.id;

  const onDelete = () => {
    setFile([]);
  };

  const handleSubmit = () => (e) => {
    setLocalLoading(true);
    const form = new FormData();
    form.append("file", file[0].file);
    uploadCertificate(agentId, hash, form).then(({ status }) => {
      setLocalLoading(false);
      if (status !== 200) {
        // return some error
      } else {
        setOpen(false);
        setFile([]);
      }
    });
  };

  const updateFiles = (incommingFiles) => {
    if (!file.length) {
      setFile(incommingFiles);
    } else {
      setFile([]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.certBtn}
        size="large"
        onClick={handleOpen}
      >
        <BackupIcon className={classes.icon} />
        Качи Сертификат
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Качване на Сертификат</DialogTitle>
        <DialogContent>
          <Dropzone
            onChange={updateFiles}
            value={file}
            header={false}
            footer={false}
            label="Качи Сертификат"
            maxHeight="100px"
          >
            {file.map((file, i) => (
              <FileItem key={i} {...file} onDelete={onDelete} preview info />
            ))}
          </Dropzone>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            disabled={!file.length}
            onClick={handleSubmit()}
            variant="contained"
            color="primary"
          >
            {localLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Качване"
            )}
          </Button>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Отмяна
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadCertificate;
